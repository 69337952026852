import { useTranslation } from 'react-i18next'
import {
  CarouselWrapper,
  Container,
  Title,
  TitleRow,
  Card,
  QuoteText,
  CustomerName,
  CustomerPosition,
} from './quoteSection_styles'
import Carousel, { Item } from 'react-grid-carousel'

const quotesNumber = [0, 1, 2, 3]

const QuoteSection = () => {
  const { t: text } = useTranslation('translation', {
    keyPrefix: 'homepage.quoteSection',
  })

  return (
    <>
      <Container className='container'>
        <TitleRow>
          <Title>{text('title')}</Title>
        </TitleRow>

        <CarouselWrapper>
          <Carousel
            rows={1}
            cols={2}
            gap='32px'
            // containerStyle={{ width: '100%' }}
          >
            {quotesNumber.map(number => (
              <Item>
                <Card>
                  <QuoteText>{text(`quotes.${number}.text`)}</QuoteText>
                  <CustomerName>{text(`quotes.${number}.name`)}</CustomerName>
                  <CustomerPosition>{text(`quotes.${number}.position`)}</CustomerPosition>
                </Card>
              </Item>
            ))}
          </Carousel>
        </CarouselWrapper>
      </Container>
    </>
  )
}

export default QuoteSection
