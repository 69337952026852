import React from 'react'
import image from '../assest/Images/home/landing_illustration_updatedColours.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AnimatedNumbers from 'react-animated-numbers'

export default function Count() {
  const { t, i18n } = useTranslation() //translator
  return (
    <div className='container'>
      <div className='row '>
        <div className='home_desktop_landing_page'>
          {/* <div className="home_first_row">  */}
          {/* <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12"> */}
          <div className='home_first_content text-left w-full mb-20'>
            {t('model_service_contact') === 'Contact us' ? (
              <h1 style={{ fontFamily: 'Exo2-Bold' }} className='home_first_content_head'>
                {/* Make data-driven decisions. */}

                {t('Home_head1')}
                <br />
                {t('Home_head2')}
              </h1>
            ) : (
              <h1 style={{ fontFamily: 'Exo2-Bold' }} className='home_first_content_head_de'>
                {/* Make data-driven decisions. */}

                {t('Home_head1')}
                <br />
                {t('Home_head2')}
              </h1>
            )}
            {t('model_service_contact') === 'Contact us' ? (
              <p style={{ fontFamily: 'NunitoSans-Regular', width: '682px' }} className='home_first_subcontent'>
                {t('Home_content')}
              </p>
            ) : (
              <p style={{ fontFamily: 'NunitoSans-Regular', width: '645px' }} className='home_first_subcontent1'>
                {t('Home_content')}
              </p>
            )}

            <div style={{ margin: '0px' }} className=' text-left mx-auto'>
              <div className='flex flex-col text-left w-full desktop'>
                <h2 style={{ fontFamily: 'Exo2-Bold' }} className='home_first_subhead'>
                  {t('Home_number_head')}
                </h2>
              </div>

              <div className='flex flex-col text-left w-full mobile '>
                <h2 style={{ fontFamily: 'Exo2-SemiBold' }} className='home_first_subhead'>
                  {t('Home_number_head')}
                </h2>
              </div>

              <div className='home_first_content_numberSection'>
                <div className='home_first_content_number1'>
                  <p style={{ fontFamily: 'Exo2-Bold' }} className='home_first_nums'>
                    <AnimatedNumbers
                      animateToNumber={65}
                      fontStyle={{
                        fontWeight: 700,
                        fontSize: '40px',
                        lineHeight: '55px',
                        color: '#2F2F2F',
                      }}
                      configs={[
                        { mass: 1, tension: 130, friction: 60 },
                        { mass: 2, tension: 140, friction: 60 },
                      ]}
                    />
                  </p>
                  <p style={{ fontFamily: 'NunitoSans-Regular' }} className='home_first_nums_p'>
                    {t('Home_number_text1')}
                  </p>
                </div>

                <div className='home_first_content_number2'>
                  <p style={{ fontFamily: 'Exo2-Bold' }} className='home_first_nums'>
                    <AnimatedNumbers
                      animateToNumber={500000}
                      includeComma
                      fontStyle={{
                        fontWeight: 700,
                        fontSize: '40px',
                        lineHeight: '55px',
                        color: '#2F2F2F',
                      }}
                      configs={[
                        { mass: 1, tension: 130, friction: 40 },
                        { mass: 2, tension: 140, friction: 40 },
                        { mass: 3, tension: 130, friction: 40 },
                      ]}
                    />
                  </p>
                  <p style={{ fontFamily: 'NunitoSans-Regular' }} className='home_first_nums_p'>
                    {t('Home_number_text2')}
                  </p>
                </div>

                <div className='home_first_content_number3'>
                  <p style={{ fontFamily: 'Exo2-Bold' }} className='home_first_nums'>
                    <AnimatedNumbers
                      animateToNumber={4000}
                      includeComma
                      fontStyle={{
                        fontWeight: 700,
                        fontSize: '40px',
                        lineHeight: '55px',
                        color: '#2F2F2F',
                      }}
                      configs={[
                        { mass: 1, tension: 130, friction: 50 },
                        { mass: 2, tension: 140, friction: 50 },
                        { mass: 3, tension: 130, friction: 50 },
                      ]}
                    />
                  </p>
                  <p style={{ fontFamily: 'NunitoSans-Regular' }} className='home_first_nums_p'>
                    {t('Home_number_text3')}{' '}
                  </p>
                </div>
              </div>

              <div className='relative top-10 home_first_content_contact'>
                {t('model_service_contact') === 'Contact us' ? (
                  <Link style={{ all: 'unset' }} to='/contact'>
                    <button
                      style={{
                        fontFamily: 'NunitoSans-Regular',
                      }}
                      className='contact_us'
                    >
                      {t('model_service_contact')}
                    </button>
                  </Link>
                ) : (
                  <Link style={{ all: 'unset' }} to='/de/kontakt'>
                    <button
                      style={{
                        fontFamily: 'NunitoSans-Regular',
                      }}
                      className='contact_us'
                    >
                      {t('model_service_contact')}
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>

          {/* </div> */}

          {/* <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12 home_first_image" style={{zIndex: '-12'}}> */}
          <div className='home_first_image img-responsive' style={{ zIndex: '-12' }}>
            <img
              className='object-cover object-center rounded'
              // style={{marginLeft: '35px', marginTop: '-30px'}}
              alt='hero'
              src={image}
            />
          </div>
          {/* </div> */}
        </div>

        <div className='home_mobile_landing_page'>
          <div className='home_first_content text-left w-full mb-20'>
            {t('model_service_contact') === 'Contact us' ? (
              <h1 style={{ fontFamily: 'Exo2-Bold' }} className='home_first_content_head'>
                {/* Make data-driven decisions. */}

                {t('Home_head1')}
                <br />
                {t('Home_head2')}
              </h1>
            ) : (
              <h1 style={{ fontFamily: 'Exo2-Bold' }} className='home_first_content_head_de'>
                {/* Make data-driven decisions. */}

                {t('Home_head1')}
                <br />
                {t('Home_head2')}
              </h1>
            )}
            {t('model_service_contact') === 'Contact us' ? (
              <p style={{ fontFamily: 'NunitoSans-Regular', width: '682px' }} className='home_first_subcontent'>
                {t('Home_content')}
              </p>
            ) : (
              <p style={{ fontFamily: 'NunitoSans-Regular', width: '645px' }} className='home_first_subcontent1'>
                {t('Home_content')}
              </p>
            )}

            <div style={{ margin: '0px' }} className=' text-left mx-auto'>
              <div className='flex flex-col text-left w-full '>
                <h2 style={{ fontFamily: 'Exo2-Bold' }} className='home_first_subhead'>
                  {t('Home_number_head')}
                </h2>
              </div>

              <div className='home_first_content_numberSection'>
                <div className='home_first_content_number1'>
                  <p style={{ fontFamily: 'Exo2-Bold' }} className='home_first_nums'>
                    <AnimatedNumbers
                      animateToNumber={65}
                      fontStyle={{
                        fontWeight: 700,
                        fontSize: '20px',
                        lineHeight: '24px',
                        color: '#2F2F2F',
                      }}
                      configs={[
                        { mass: 1, tension: 130, friction: 60 },
                        { mass: 2, tension: 140, friction: 60 },
                      ]}
                    />
                  </p>
                  <p style={{ fontFamily: 'NunitoSans-Regular' }} className='home_first_nums_p'>
                    {t('Home_number_text1')}
                  </p>
                </div>

                <div className='home_first_content_number2'>
                  <p style={{ fontFamily: 'Exo2-Bold' }} className='home_first_nums'>
                    <AnimatedNumbers
                      animateToNumber={500000}
                      includeComma
                      fontStyle={{
                        fontWeight: 700,
                        fontSize: '20px',
                        lineHeight: '24px',
                        color: '#2F2F2F',
                      }}
                      configs={[
                        { mass: 1, tension: 130, friction: 40 },
                        { mass: 2, tension: 140, friction: 40 },
                        { mass: 3, tension: 130, friction: 40 },
                      ]}
                    />
                  </p>
                  <p style={{ fontFamily: 'NunitoSans-Regular' }} className='home_first_nums_p'>
                    {t('Home_number_text2')}
                  </p>
                </div>

                <div className='home_first_content_number3'>
                  <p style={{ fontFamily: 'Exo2-Bold' }} className='home_first_nums'>
                    <AnimatedNumbers
                      animateToNumber={4000}
                      includeComma
                      fontStyle={{
                        fontWeight: 700,
                        fontSize: '20px',
                        lineHeight: '24px',
                        color: '#2F2F2F',
                      }}
                      configs={[
                        { mass: 1, tension: 130, friction: 50 },
                        { mass: 2, tension: 140, friction: 50 },
                        { mass: 3, tension: 130, friction: 50 },
                      ]}
                    />
                  </p>
                  <p style={{ fontFamily: 'NunitoSans-Regular' }} className='home_first_nums_p'>
                    {t('Home_number_text3')}{' '}
                  </p>
                </div>
              </div>

              <div className='home_mobile_first_image img-responsive' style={{ zIndex: '-12' }}>
                <img
                  className='object-cover object-center rounded'
                  // style={{marginLeft: '35px', marginTop: '-30px'}}
                  alt='hero'
                  src={image}
                />
              </div>
              <div className='relative top-10 home_first_content_contact'>
                {t('model_service_contact') === 'Contact us' ? (
                  <Link style={{ all: 'unset' }} to='/contact'>
                    <button
                      style={{
                        fontFamily: 'NunitoSans-Regular',
                      }}
                      className='contact_us'
                    >
                      {t('model_service_contact')}
                    </button>
                  </Link>
                ) : (
                  <Link style={{ all: 'unset' }} to='/de/kontakt'>
                    <button
                      style={{
                        fontFamily: 'NunitoSans-Regular',
                      }}
                      className='contact_us'
                    >
                      {t('model_service_contact')}
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
