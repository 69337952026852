import React from "react";
import { useTranslation } from 'react-i18next';//translator
import { Link } from "react-router-dom";

export default function ProjectFoot() {
  const { t, i18n } = useTranslation();//translator
  return (
    <div className="">
    <div className="row get_in_touch_cotainer" >
          <div className="get_in_touch_desktop flex flex-wrap w-full flex-col  items-center text-center">
            <h3 style={{ fontFamily: "Exo2-Regular" }} className="get_in_touch">
              {/* Get in touch to learn more about our project  */}
              {t('project_section6_text1')}
            </h3>
            <h3 style={{ fontFamily: "Exo2-Regular" }} className="get_in_touch">
            {t('project_section6_text2')}
            </h3>
          </div>
          <div className="get_in_touch_mobile flex flex-wrap w-full flex-col  items-center text-center">
          {t('model_service_contact') === "Contact us" ?
            <h3 style={{ fontFamily: "Exo2-Regular" }} className="get_in_touch">
              {/* Get in touch to learn more about our project  */}
              {t('project_section6_text_mobile')}
            </h3>
            :
            <h3 style={{ fontFamily: "Exo2-Regular" }} className="get_in_touch get_in_touch_de">
              {/* Get in touch to learn more about our project  */}
              {t('project_section6_text_mobile')}
            </h3>
          }

          </div>
          {t('model_service_contact') === "Contact us" ?
          <Link  to="/contact">
          <button
            style={{
              fontFamily: "NunitoSans-Regular",
               marginBottom:'120px'
            }}
            className="contact_us"
          >
            {t('model_service_contact')}
          </button>
          </Link>
          :
          <Link  to="/de/kontakt">
          <button
            style={{
              fontFamily: "NunitoSans-Regular",
               marginBottom:'120px'
            }}
            className="contact_us"
          >
            {t('model_service_contact')}
          </button>
          </Link>
        
        }
    </div>
    </div>
  );
}
