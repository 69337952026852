import React, { useEffect, useState } from "react";
import "./Conindex.css";
// import CountryFlag from "./CountryFlag";
import relaxed from "../../assest/Images/contact/contact_01.svg";
import building from "../../assest/Images/contact/contact_02.svg";
import tower from "../../assest/Images/Berlin_TVTower.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';//translator
import $ from "jquery";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function Index() {
  const { t, i18n } = useTranslation();//translator
  // console.log(t, 'ttttt')

  const [verified, setverified] = useState(false);
  const [en, seten] = useState(true);
  const [de, setde] = useState(false);
  const [agree, setagree] = useState(false);
  const [tabagree, tabsetagree] = useState(false);
  const [mobagree, mobsetagree] = useState(false);

  console.log("Captcha value:", verified);
  // contact_form_meeting_font
  // var meetingLanguage = document.getElementsByClassName('contact_form_meeting_font').innerHTML;


  function handleContact(value) {
    if(value !=='0' ) {
      setverified(true);
    } else {
      setverified(false);
    }
    console.log("Captcha value:", verified);
  }

  const changeLangEn = () => { 
    seten(true);
    setde(false);
  }; 

  const changeLangDe = () => { 
    seten(false);
    setde(true);
  }; 

  const iAgree = () => { 
    // setagree(true);
  }; 

  // console.log(agree, 'bkb'); // true (if checked at the time);

  $('#contact_form_submitButton').prop('disabled', true);

  function handleSubmitbtn(value) {
    let isChecked = $('#iagree').is(':checked');
    if(isChecked) {
      setagree(true)
    } else {
      setagree(false)
    }
    // console.log(isChecked); // true (if checked at the time);
    // console.log(agree); // true (if checked at the time);
  }

  // console.log(mobagree, 'njask'); 


  function handleMobSubmitbtn(value) {
    let isChecked = $('#mob_iagree').is(':checked');
    if(isChecked) {
      mobsetagree(true)
    } else {
      mobsetagree(false)
    }
    // console.log(isChecked); // true (if checked at the time);
    // console.log(mobagree); // true (if checked at the time);
  }

  function handleTabSubmitbtn(value) {
    let isChecked = $('#tab_iagree').is(':checked');
    if(isChecked) {
      tabsetagree(true)
    } else {
      tabsetagree(false)
    }
    // console.log(isChecked); // true (if checked at the time);
    console.log(tabagree); // true (if checked at the time);
  }
  
  
 
  const [interestedIN, setInterestedIN] = useState([]);
  const [represent, setRepresent] = useState([]);
  const [mobinterestedIN, setMobInterestedIN] = useState([]);
  const [mobrepresent, setMobRepresent] = useState([]);
  const [tabinterestedIN, setTabInterestedIN] = useState([]);
  const [tabrepresent, setTabRepresent] = useState([]);
  const url = window.location.href;
  
  const [sitekey, setsitekey] = useState(() => {
    if(url.includes('leaftech.de')) {
      return '6LewlZkiAAAAALm-9cARtX9zjhlw4o_n8H5-NywQ'
    } else if(url.includes('leaftech.eu')) {
      return '6LdGk5kiAAAAAD1lVZrTuQtyBFZgXySJUIyw0XpT'
    }  else if(url.includes('websitetest.leaftech')) {
      return '6LfXIzwiAAAAADDM5qaC8OamV0s9iFEEAJz7bFQk'
    } else {
      return '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
    }

  });

  const [secretkey, setsecretkey] = useState(() => {
    if(url.includes('leaftech.de')) {
      return '6LewlZkiAAAAADyRgSQFDIDf5b_83w1LJifLIg8Y'
    } else if(url.includes('leaftech.eu')) {
      return '6LdGk5kiAAAAAJv9iQgLsbHm-cVwOYzJ0IS84hkI'
    }  else if(url.includes('websitetest.leaftech')) {
      return '6LfXIzwiAAAAAPLQz-GBzSzX8joQJNs49oC0zGB6'
    } else {
      return '6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe'
    }

  });

  // const [num, setNum] = useState(() => {
  //   if (2 * 2 === 4) {
  //     return 4;
  //   }

  //   return 42;
  // });
   
  // const [sitekey, setsitekey] = useState('6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI');
  // const [secretkey, setsecretkey] = useState('vFI1TnRWxMZNFuojJ4WifJWe');

  

  useEffect(()=> {

    var url = window.location.href;

    // if(sitekey == null) {
    //   sitekey ='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
    //   secretkey ='6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe';
    // }
      
    
    // if(url.includes('leaftech.de')) {
    //   setsitekey('6LewlZkiAAAAALm-9cARtX9zjhlw4o_n8H5-NywQ')
    //   setsecretkey('6LewlZkiAAAAADyRgSQFDIDf5b_83w1LJifLIg8Y')
    //   console.log('url', url)
    //   console.log('sitekey', sitekey)
    //   console.log('secretkey', secretkey)
    // } else if(url.includes('leaftech.eu')) {
    //   setsitekey('6LdGk5kiAAAAAD1lVZrTuQtyBFZgXySJUIyw0XpT')
    //   setsecretkey('6LdGk5kiAAAAAJv9iQgLsbHm-cVwOYzJ0IS84hkI')
    //   console.log('url', url)
    //   console.log('sitekey', sitekey)
    //   console.log('secretkey', secretkey)
    // }  else if(url.includes('websitetest.leaftech')) {
    //   setsitekey('6LfXIzwiAAAAADDM5qaC8OamV0s9iFEEAJz7bFQk')
    //   setsecretkey('6LfXIzwiAAAAAPLQz-GBzSzX8joQJNs49oC0zGB6')
    //   console.log('url', url)
    //   console.log('sitekey', sitekey)
    //   console.log('secretkey', secretkey)
    // } else {
    //   setsitekey('6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI')
    //   setsecretkey('6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe')
    //   console.log('url', url)
    //   console.log('sitekey', sitekey)
    //   console.log('secretkey', secretkey)
    // }

    // var meetingLanguage = $('.contact_form_meeting_font').text();
    // console.log(meetingLanguage, 'meetingLanguage')
    // if(meetingLanguage !== 'Meeting language') {
    //   changeLangDe()
    //   console.log( 'de')
    // } else {
    //   changeLangEn()
    //   console.log( 'en')
    // }

    if(url.includes('/de')) {
      changeLangDe()
      console.log( 'de')
    } else if(url.includes('.de')) {
      changeLangDe()
      console.log( 'de')
    }  else if(url.includes('/deu')) {
      changeLangDe()
      console.log( 'de')
    } else {
      changeLangEn()
      console.log( 'en')
    }

    
    // console.log('url', url)
    // console.log('sitekey', sitekey)
    // console.log('secretkey', secretkey)
    setInterestedIN([
      {name:t('contact_subhead_buttons_text11'), isActive:false},
      {name:t('contact_subhead_buttons_text12'), isActive:false},
    ]);

    setRepresent([
      {name:t('contact_subhead_buttons_text1'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text2'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text3'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text4'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text5'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text6'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text7'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text8'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text9'), isActivetoo:false},
    ]);

    setMobInterestedIN([
      {name:t('contact_subhead_buttons_text11'), isActive:false},
      {name:t('contact_subhead_buttons_text12'), isActive:false},
    ]);

    setMobRepresent([
      {name:t('contact_subhead_buttons_text1'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text2'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text3'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text4'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text5'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text6'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text7'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text8'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text9'), isActivetoo:false},
    ]);

    setTabInterestedIN([
      {name:t('contact_subhead_buttons_text11'), isActive:false},
      {name:t('contact_subhead_buttons_text12'), isActive:false},
    ]);

    setTabRepresent([
      {name:t('contact_subhead_buttons_text1'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text2'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text3'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text4'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text5'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text6'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text7'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text8'), isActivetoo:false},
      {name:t('contact_subhead_buttons_text9'), isActivetoo:false},
    ]);

  },[t]);
 
  

  const toggleClassInt = (item) => {
    const indexVal = interestedIN.findIndex(x=>x.name == item.name);
    interestedIN.splice(indexVal,1,{...item, isActive: !item.isActive});
    setInterestedIN([...interestedIN]);
  };
  

  const toggleClassRep = (item) => {
    const indexVal = represent.findIndex(x=>x.name == item.name);
    represent.splice(indexVal,1,{...item, isActivetoo: !item.isActivetoo});
    setRepresent([...represent]);
  };

  const mobtoggleClassInt = (item) => {
    const indexVal = mobinterestedIN.findIndex(x=>x.name == item.name);
    mobinterestedIN.splice(indexVal,1,{...item, isActive: !item.isActive});
    setMobInterestedIN([...mobinterestedIN]);
  };
  

  const mobtoggleClassRep = (item) => {
    const indexVal = mobrepresent.findIndex(x=>x.name == item.name);
    mobrepresent.splice(indexVal,1,{...item, isActivetoo: !item.isActivetoo});
    setMobRepresent([...mobrepresent]);
  };

  const tabtoggleClassInt = (item) => {
    const indexVal = tabinterestedIN.findIndex(x=>x.name == item.name);
    tabinterestedIN.splice(indexVal,1,{...item, isActive: !item.isActive});
    setTabInterestedIN([...tabinterestedIN]);
  };
  

  const tabtoggleClassRep = (item) => {
    const indexVal = tabrepresent.findIndex(x=>x.name == item.name);
    tabrepresent.splice(indexVal,1,{...item, isActivetoo: !item.isActivetoo});
    setTabRepresent([...tabrepresent]);
  };

  // console.log(interestedIN,'interestedIN')
  // console.log(represent,'represent')

  
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');

  const [isShow, invokeModal] = React.useState(false)

  
  const initModal = () => {
    return invokeModal(!false)
  }

  const closeModal = () => {
    return invokeModal(!true)
  }


  const submitContactInfo = async () => {

    var name = $("#name").val();
    var job_title = $("#job_title").val();  
    var email =  $("#email").val();

    if($("#name").val() === '') {
      // $("#name").css('border', '1px solid #EA0069')
      $("#name").addClass('contact_form_longInput_error')
      $("#name").removeClass('contact_form_longInput')
      $("#name_error_text").css('display', 'block')
    } else {
      $("#name_error_text").css('display', 'none')
      $("#name").removeClass('contact_form_longInput_error')
      $("#name").addClass('contact_form_longInput')
    }

    if($("#job_title").val() === '') {
      // $("#job_title").css('border', '1px solid #EA0069')
      $("#job_title").addClass('contact_form_longInput_error')
      $("#job_title").removeClass('contact_form_longInput')
      $("#job_error_text").css('display', 'block')
    } else {
      $("#job_error_text").css('display', 'none')
      $("#job_title").removeClass('contact_form_longInput_error')
      $("#job_title").addClass('contact_form_longInput')
    }

    if($("#email").val() === '') {
      // $("#email").css('border', '1px solid #EA0069')
      $("#email").addClass('contact_form_shortInput_error')
      $("#email").removeClass('contact_form_shortInput')
      $("#email_error_text").css('display', 'block')
    } else {
      $("#email_error_text").css('display', 'none')
      $("#email").removeClass('contact_form_shortInput_error')
      $("#email").addClass('contact_form_shortInput')
    }
    
    setIsLoading(true);

    // console.log(interestedIN.length, represent.length, 'length')
    // console.log(interestedIN.filter(word => word.isActive === true),'interestedIN')
    // console.log(represent.filter(word => word.isActivetoo === true),'represent')

    var intrestarray = interestedIN.filter(word => word.isActive === true)
    var representarray = represent.filter(word => word.isActivetoo === true)

    // console.log(intrestarray.length, representarray.length, 'length')
    // console.log(intrestarray,'intrestarray')
    // console.log(representarray,'representarray')

    var mainintrestarray = []
    var mainrepresentarray = []
    for(var i=0; i < intrestarray.length; i++) {
      console.log(intrestarray,i,'intrestarray')
      mainintrestarray.push(intrestarray[i].name)    
    }

    for(var i=0; i < representarray.length; i++) {
      console.log(representarray,i,'representarray')
      mainrepresentarray.push(representarray[i].name)    
    }

    // console.log(mainintrestarray.length, mainrepresentarray.length, 'length')
    // console.log(mainintrestarray,'mainintrestarray')
    // console.log(mainrepresentarray,'mainrepresentarray')

    if(mainintrestarray.length === 0) {
      $("#intrested_error_text").css('display', 'block')
    } else if(interestedIN.length ){
      $("#intrested_error_text").css('display', 'none')
    }

    if(mainrepresentarray.length === 0) {
      $("#represent_error_text").css('display', 'block')
    } else if(represent.length ){
      $("#represent_error_text").css('display', 'none')
    }

    if(!verified) {
      alert('Please check the captcha')
    }
    
    if(!agree) {
      alert('Please agree to our Privacy policy')
    }

    if(agree && name && job_title && email && mainintrestarray.length !== 0 && mainrepresentarray.length !== 0) {
    try {
      const response = await fetch('https://website-api.leaftech-products.com/create_contact/', {
        method: 'POST',
        body: JSON.stringify({
          name : $("#name").val(),
          job_title : $("#job_title").val(),
          company : $("#company").val(),
          email : $("#email").val(),
          phone_no : $("#phone_no").val(),
          short_note : $("#short_note").val(),
          // language : $("#language").val(),
          // mainintrestarray,'mainrepresentarray'
          list_of_interest : mainintrestarray,
          list_of_groups : mainrepresentarray,
          language : en ? "en" : "de",
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      console.log('response: ', response);


      if (!response.ok) {
        alert('Message not sent')
        throw new Error(`Error! status: ${response.status}`);
      } else {
        // alert('success')
        invokeModal(!false)
        // $('#successModal').css('display', 'block')
         $("#name").val('');
         $("#job_title").val('');
         $("#company").val('');
         $("#email").val('');
         $("#phone_no").val('');
         $("#short_note").val('');
         mainintrestarray = [];
         mainrepresentarray = [];
         setInterestedIN([
          {name:t('contact_subhead_buttons_text11'), isActive:false},
          {name:t('contact_subhead_buttons_text12'), isActive:false},
        ]);
    
        setRepresent([
          {name:t('contact_subhead_buttons_text1'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text2'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text3'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text4'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text5'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text6'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text7'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text8'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text9'), isActivetoo:false},
        ]);

        // setverified(false)
        setagree(false)
         $('#name_error_text').css('display', 'none') 
         $('#job_error_text').css('display', 'none') 
         $('#email_error_text').css('display', 'none') 
         $('#intrested_error_text').css('display', 'none') 
         $('#represent_error_text').css('display', 'none')
      }

      const result = await response.json();

      console.log('result is: ', JSON.stringify(result, null, 4));

      setData(result);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
    } else {
      alert('Please fill all mandatory values')
    }
  };

  const mobSubmitContactInfo = async () => {

    var name = $("#mob_name").val();
    var job_title = $("#mob_job_title").val();  
    var email =  $("#mob_email").val();

    if($("#mob_name").val() === '') {
      // $("#mob_name").css('border', '1px solid #mob_EA0069')
      $("#mob_name").addClass('contact_form_longInput_error')
      $("#mob_name").removeClass('contact_form_longInput')
      $("#mob_name_error_text").css('display', 'block')
    } else {
      $("#mob_name_error_text").css('display', 'none')
      $("#mob_name").removeClass('contact_form_longInput_error')
      $("#mob_name").addClass('contact_form_longInput')
    }

    if($("#mob_job_title").val() === '') {
      // $("#mob_job_title").css('border', '1px solid #mob_EA0069')
      $("#mob_job_title").addClass('contact_form_longInput_error')
      $("#mob_job_title").removeClass('contact_form_longInput')
      $("#mob_job_error_text").css('display', 'block')
    } else {
      $("#mob_job_error_text").css('display', 'none')
      $("#mob_job_title").removeClass('contact_form_longInput_error')
      $("#mob_job_title").addClass('contact_form_longInput')
    }

    if($("#mob_email").val() === '') {
      // $("#mob_email").css('border', '1px solid #mob_EA0069')
      $("#mob_email").addClass('contact_form_shortInput_error')
      $("#mob_email").removeClass('contact_form_shortInput')
      $("#mob_email_error_text").css('display', 'block')
    } else {
      $("#mob_email_error_text").css('display', 'none')
      $("#mob_email").removeClass('contact_form_shortInput_error')
      $("#mob_email").addClass('contact_form_shortInput')
    }
    
    setIsLoading(true);

    // console.log(interestedIN.length, represent.length, 'length')
    // console.log(interestedIN.filter(word => word.isActive === true),'interestedIN')
    // console.log(represent.filter(word => word.isActivetoo === true),'represent')

    var intrestarraymob = mobinterestedIN.filter(word => word.isActive === true)
    var representarraymob = mobrepresent.filter(word => word.isActivetoo === true)

    // console.log(intrestarray.length, representarray.length, 'length')
    console.log(intrestarraymob,'intrestarray')
    console.log(representarraymob,'representarray')

    var mainintrestarraymob = []
    var mainrepresentarraymob = []
    for(var i=0; i < intrestarraymob.length; i++) {
      console.log(intrestarraymob,i,'intrestarraymob')
      mainintrestarraymob.push(intrestarraymob[i].name)    
    }

    for(var i=0; i < representarraymob.length; i++) {
      console.log(representarraymob,i,'mainrepresentarraymob')
      mainrepresentarraymob.push(representarraymob[i].name)    
    }

    // console.log(mainintrestarraymob.length, mainrepresentarraymob.length, 'length')
    // console.log(mainintrestarraymob,'mainintrestarraymob')
    // console.log(mainrepresentarraymob,'mainrepresentarraymob')

    if(mainintrestarraymob.length === 0) {
      $("#mob_intrested_error_text").css('display', 'block')
    } else if(interestedIN.length ){
      $("#mob_intrested_error_text").css('display', 'none')
    }

    if(mainrepresentarraymob.length === 0) {
      $("#mob_represent_error_text").css('display', 'block')
    } else if(represent.length ){
      $("#mob_represent_error_text").css('display', 'none')
    }

    if(!verified) {
      alert('Please check the captcha')
    }
    
    if(!mobagree) {
      alert('Please agree to our Privacy policy')
    }

    if(mobagree && name && job_title && email && mainintrestarraymob.length !== 0 && mainrepresentarraymob.length !== 0) {
    try {
      const response = await fetch('https://website-api.leaftech-products.com/create_contact/', {
        method: 'POST',
        body: JSON.stringify({
          name : $("#mob_name").val(),
          job_title : $("#mob_job_title").val(),
          company : $("#mob_company").val(),
          email : $("#mob_email").val(),
          phone_no : $("#mob_phone_no").val(),
          short_note : $("#mob_short_note").val(),
          // language : $("#language").val(),
          // mainintrestarraymob,'mainrepresentarraymob'
          list_of_interest : mainintrestarraymob,
          list_of_groups : mainrepresentarraymob,
          language : en ? "en" : "de",
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      console.log('response: ', response);


      if (!response.ok) {
        alert('Message not sent')
        throw new Error(`Error! status: ${response.status}`);
      } else {
        // alert('success')
        invokeModal(!false)
        // $('#successModal').css('display', 'block')
         $("#mob_name").val('');
         $("#mob_job_title").val('');
         $("#mob_company").val('');
         $("#mob_email").val('');
         $("#mob_phone_no").val('');
         $("#mob_short_note").val('');
         mainintrestarraymob = [];
         mainrepresentarraymob = [];
         setMobInterestedIN([
          {name:t('contact_subhead_buttons_text11'), isActive:false},
          {name:t('contact_subhead_buttons_text12'), isActive:false},
        ]);
    
        setMobRepresent([
          {name:t('contact_subhead_buttons_text1'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text2'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text3'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text4'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text5'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text6'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text7'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text8'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text9'), isActivetoo:false},
        ]);

        // setverified(false)
        mobsetagree(false)

         $('#mob_name_error_text').css('display', 'none') 
         $('#mob_job_error_text').css('display', 'none') 
         $('#mob_email_error_text').css('display', 'none') 
         $('#mob_intrested_error_text').css('display', 'none') 
         $('#mob_represent_error_text').css('display', 'none')
      }

      const result = await response.json();

      console.log('result is: ', JSON.stringify(result, null, 4));

      setData(result);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
    } else {
      alert('Please fill all mandatory values')
    }
  };

  const submitTabContactInfo = async () => {

    var name = $("#tab_name").val();
    var job_title = $("#tab_job_title").val();  
    var email =  $("#tab_email").val();

    if($("#tab_name").val() === '') {
      // $("#tab_name").css('border', '1px solid #tab_EA0069')
      $("#tab_name").addClass('contact_form_longInput_error')
      $("#tab_name").removeClass('contact_form_longInput')
      $("#tab_name_error_text").css('display', 'block')
    } else {
      $("#tab_name_error_text").css('display', 'none')
      $("#tab_name").removeClass('contact_form_longInput_error')
      $("#tab_name").addClass('contact_form_longInput')
    }

    if($("#tab_job_title").val() === '') {
      // $("#tab_job_title").css('border', '1px solid #tab_EA0069')
      $("#tab_job_title").addClass('contact_form_longInput_error')
      $("#tab_job_title").removeClass('contact_form_longInput')
      $("#tab_job_error_text").css('display', 'block')
    } else {
      $("#tab_job_error_text").css('display', 'none')
      $("#tab_job_title").removeClass('contact_form_longInput_error')
      $("#tab_job_title").addClass('contact_form_longInput')
    }

    if($("#tab_email").val() === '') {
      // $("#tab_email").css('border', '1px solid #tab_EA0069')
      $("#tab_email").addClass('contact_form_shortInput_error')
      $("#tab_email").removeClass('contact_form_shortInput')
      $("#tab_email_error_text").css('display', 'block')
    } else {
      $("#tab_email_error_text").css('display', 'none')
      $("#tab_email").removeClass('contact_form_shortInput_error')
      $("#tab_email").addClass('contact_form_shortInput')
    }
    
    setIsLoading(true);

    // console.log(interestedIN.length, represent.length, 'length')
    // console.log(interestedIN.filter(word => word.isActive === true),'interestedIN')
    // console.log(represent.filter(word => word.isActivetoo === true),'represent')

    var intrestarraytab = tabinterestedIN.filter(word => word.isActive === true)
    var representarraytab = tabrepresent.filter(word => word.isActivetoo === true)

    // console.log(intrestarray.length, representarray.length, 'length')
    console.log(intrestarraytab,'intrestarray')
    console.log(representarraytab,'representarray')

    var mainintrestarraytab = []
    var mainrepresentarraytab = []
    for(var i=0; i < intrestarraytab.length; i++) {
      console.log(intrestarraytab,i,'intrestarraytab')
      mainintrestarraytab.push(intrestarraytab[i].name)    
    }

    for(var i=0; i < representarraytab.length; i++) {
      console.log(representarraytab,i,'mainrepresentarraytab')
      mainrepresentarraytab.push(representarraytab[i].name)    
    }

    // console.log(mainintrestarraytab.length, mainrepresentarraytab.length, 'length')
    // console.log(mainintrestarraytab,'mainintrestarraytab')
    // console.log(mainrepresentarraytab,'mainrepresentarraytab')

    if(mainintrestarraytab.length === 0) {
      $("#tab_intrested_error_text").css('display', 'block')
    } else if(interestedIN.length ){
      $("#tab_intrested_error_text").css('display', 'none')
    }

    if(mainrepresentarraytab.length === 0) {
      $("#tab_represent_error_text").css('display', 'block')
    } else if(represent.length ){
      $("#tab_represent_error_text").css('display', 'none')
    }

    if(!verified) {
      alert('Please check the captcha')
    }
    
    if(!tabagree) {
      alert('Please agree to our Privacy policy')
    }

    if(tabagree && name && job_title && email && mainintrestarraytab.length !== 0 && mainrepresentarraytab.length !== 0) {
    try {
      const response = await fetch('https://website-api.leaftech-products.com/create_contact/', {
        method: 'POST',
        body: JSON.stringify({
          name : $("#tab_name").val(),
          job_title : $("#tab_job_title").val(),
          company : $("#tab_company").val(),
          email : $("#tab_email").val(),
          phone_no : $("#tab_phone_no").val(),
          short_note : $("#tab_short_note").val(),
          // language : $("#language").val(),
          // mainintrestarraytab,'mainrepresentarraytab'
          list_of_interest : mainintrestarraytab,
          list_of_groups : mainrepresentarraytab,
          language : en ? "en" : "de",
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      console.log('response: ', response);


      if (!response.ok) {
        alert('Message not sent')
        throw new Error(`Error! status: ${response.status}`);
      } else {
        // alert('success')
        invokeModal(!false)
        // $('#successModal').css('display', 'block')
         $("#tab_name").val('');
         $("#tab_job_title").val('');
         $("#tab_company").val('');
         $("#tab_email").val('');
         $("#tab_phone_no").val('');
         $("#tab_short_note").val('');
         mainintrestarraytab = [];
         mainrepresentarraytab = [];
         setTabInterestedIN([
          {name:t('contact_subhead_buttons_text11'), isActive:false},
          {name:t('contact_subhead_buttons_text12'), isActive:false},
        ]);
    
        setTabRepresent([
          {name:t('contact_subhead_buttons_text1'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text2'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text3'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text4'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text5'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text6'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text7'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text8'), isActivetoo:false},
          {name:t('contact_subhead_buttons_text9'), isActivetoo:false},
        ]);

        // setverified(false)
        tabsetagree(false)
        
         $('#tab_name_error_text').css('display', 'none') 
         $('#tab_job_error_text').css('display', 'none') 
         $('#tab_email_error_text').css('display', 'none') 
         $('#tab_intrested_error_text').css('display', 'none') 
         $('#tab_represent_error_text').css('display', 'none')
      }

      const result = await response.json();

      console.log('result is: ', JSON.stringify(result, null, 4));

      setData(result);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
    } else {
      alert('Please fill all mandatory values')
    }
  };

  // console.log(data);

      // console.log(
    //   'name-', name,
    //   'job_title-', job_title,
    //   'company-', company,
    //   'email-', email,
    //   'phone_no-', phone_no,
    //   'short_note-', short_note,
    //   'language-', language,
    // )

   
 


  return (
    <div className="contact_page_top_container">
       {t('model_service_contact') === "Contact us" ?
        <Helmet>
          <title>Contact Us | Leaftech</title>
          <meta name="description" content="Partner with us today to improve the performance of your building or individual systems. email us at michael.dittel@leaftech.eu" />
          <link rel="canonical" href="/contact" />
        </Helmet>
        :
        <Helmet>
          <title>Contact Us | Leaftech</title>
          <meta name="description" content="Partner with us today to improve the performance of your building or individual systems. email us at michael.dittel@leaftech.eu" />
          <link rel="canonical" href="/de/kontakt" />
        </Helmet>
      }
    <div className="container">
      <div className="row">
        <div className="row contact_desktop_view" style={{marginTop:'135px', height:'1153px', maxWidth: '104%', width: '103%'}}>
            <div  className="mx-auto flex  md:flex-row flex-col contact__leaf-wrapper">
              <div className="contact__leaf__info" style={{width:'666px', height:'506px', textAlign:'left', marginTop:'45px', marginLeft: '15px'}}>
                <h1
                  style={{ fontFamily: "Exo2-Bold" }}
                  className="contact_head"
                >
                  {t('contact_head')}
                </h1>
                <h2 style={{ fontFamily: "NunitoSans-Regular" }} className="leading-relaxed  contact_content">
                {t('contact__content')}
                </h2>
                <div className="my-5  text-left">
                  <div className="space-y-3">
                    <h3 className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>{t('contact_subhead1')}</h3>
                    <p id="intrested_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Please select at least one title from the category.</p>
                  
                    <div className="flex space-x-3">
                      {interestedIN.map((item, index)=>{
                        return(
                          <button key={`${item.name}_${item.isActive}_${index}`} style={{ fontFamily: "Exo2-Regular" }}
                      className={item.isActive ? 'main': 'contact_subhead_buttons_text'} 
                      onClick={()=>toggleClassInt(item)}  
                      >
                      {item.name}
                      </button>
                        )
                      })}

                    </div>
                  </div>
                  <div className="space-y-3" style={{marginTop:'32px'}}>
                    <p className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>{t('contact_subhead2')}</p>
                    <p id="represent_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Please select at least one title from the category.</p>
                    <div className="space-x-4 space-y-4" style={{width:'705px'}}>
                      <div className="flex" style={{display:'flex', flexWrap:'wrap', marginLeft:'-15px'}}>

                        {represent.map((item, index)=>{
                          return(
                            <button key={`${item.name}_${item.isActivetoo}_${index}`} style={{ fontFamily: "Exo2-Regular", marginLeft:'16px', marginTop:'16px' }}
                                className={item.isActivetoo ? 'main': 'contact_subhead_buttons_text'} 
                                onClick={()=>toggleClassRep(item)}  
                                >
                                {item.name}
                            </button>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  {t('contact_subhead_buttons_text9')==="Others" ?
                    <div className="grid grid-cols-2 gap-4 place-content-end ">
                  <div className="align-self-end">
                  <img className="usersit_img"  alt="relaxed" src={relaxed} />
                </div>
                <div className="justify-end w-full">
                  <img className="building_img" alt="building" src={building} />
                </div>
                </div>
                  :
                  <div className="grid grid-cols-2 gap-4 place-content-end ">
                    <div className="align-self-end">
                    <img className="usersit_img_de"  alt="relaxed" src={relaxed} />
                  </div>
                  <div className="justify-end w-full">
                    <img className="building_img_de" alt="building" src={building} />
                  </div>
                  
                  </div>
                      }
                </div>
              </div>
              <div className="rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 contact__leaf__form" style={{width: '520px', height:'763px'}}>
                <div className="flex justify-end text-[12px] space-x-4" style={{alignItems:'center', marginBottom:'24px', marginTop:'35px'}}>
                  <p className="contact_form_meeting_font tracking-widest" style={{ fontFamily: "Exo2-Regular" }}>{t('contact_form_meeting')}</p>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <input className="contact_form_checkbox" checked={en} onChange={changeLangEn} id="language" value="en"  type="checkbox" />
                    <label className="contact_form_lang_font">English</label>
                  </div>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <input className="contact_form_checkbox" checked={de} onChange={changeLangDe} id="language" value="de" type="checkbox" />
                    <label className="contact_form_lang_font">German</label>
                  </div>
                </div>
                <div className="relative text-left">
                  <label for="name" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                    Name*
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder= {t('contact_name_text')}
                    className="contact_form_longInput drop-shadow-sm bg-white rounded-[10px] border  text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                  <p id="name_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Required</p>
                </div>
                <div className="relative text-left">
                  <label for="job_title" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                  {t('contact_subhead_position')}
                  </label>
                  <input
                    type="text"
                    id="job_title"
                    name="Job Title"
                    placeholder= {t('contact_job_text')}
                    className="contact_form_longInput drop-shadow-sm bg-white rounded-[10px] border  text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                  <p id="job_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Required</p>
                </div>
                <div className="flex space-x-4">
                  <div className="relative text-left">
                    <label for="email" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                      Email*
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder= {t('contact_email_text')}
                      className="contact_form_shortInput drop-shadow-sm bg-white rounded-[10px] border  text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  <p id="email_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Please enter a valid email address</p>
                  </div>
                  <div className="relative text-left">
                    <label for="company" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                    {t('contact_subhead_company')}
                    </label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      placeholder= {t('contact_company_text')}
                      className="contact_form_shortInput drop-shadow-sm bg-white rounded-[10px] border  text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="relative text-left"  style={{ display: 'flex', flexDirection: 'column' }}>
                  <label for="phone_no" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                  {t('contact_subhead_number')}
                  </label>
                  {/* <CountryFlag ></CountryFlag> */}
                  <input
                    type="text"
                    id="phone_no"
                    name="phone_no"
                    placeholder="+49"
                    className="contact_form_phoneInput drop-shadow-sm bg-white rounded-[10px] border  text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <div className="relative text-left">
                  <label for="short_note" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                  {t('contact_subhead_note')}
                  </label>
                  <textarea
                    className="contact_form_shortnote"
                    id="short_note"
                    name="short_note"
                    rows="4"
                    cols="50"
                    placeholder={t('contact_note_text')}
                  />
                </div>
                <div className="flex my-2 space-x-4 text-left contact_form_Iagree_div" >
                  <input
                    type="checkbox"
                    id="iagree"
                    name="iagree"
                    onChange={iAgree}
                    // checked={agree}
                    onChange={handleSubmitbtn}
                    className="contact_form_checkbox bg-white "
                  />
                 {t('model_service_contact') === "Contact us" ? 
                  <p className="text-[12px] contact_form_Iagree"  style={{fontFamily:"NunitoSans-Regular"}}>
                    I agree to Leaftech’s <Link className="contact_form_Iagree_link" target="_blank"  to="/PrivacyPolicy">terms & conditions.</Link>
                  </p>
                  :
                   <p className="text-[12px] contact_form_Iagree"  style={{fontFamily:"NunitoSans-Regular"}}>
                     Ich stimme der <Link className="contact_form_Iagree_link" target="_blank"  to="/PrivacyPolicy">Datenschutzrichtlinie</Link> von Leaftech.
                   </p>
                  }
                </div>
                <div className="flex space-x-4 captcha" style={{marginTop:'20px'}}>
                {}  
                {/* {sitekey && sitekey?.length > 0 &&     */}
                  <ReCAPTCHA
                    // sitekey="6LfXIzwiAAAAADDM5qaC8OamV0s9iFEEAJz7bFQk"
                    // sitekey="6LcD0FAiAAAAAF0Kl22zxWxQ8lOccH1etbsiogNR"
                    // sitekey={url.includes('leaftech.de') ?  '6LewlZkiAAAAALm-9cARtX9zjhlw4o_n8H5-NywQ' : url.includes('leaftech.eu') ?  '6LdGk5kiAAAAAD1lVZrTuQtyBFZgXySJUIyw0XpT' : url.includes('websitetest.leaftech') ?  '6LfXIzwiAAAAADDM5qaC8OamV0s9iFEEAJz7bFQk' : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}                    
                    // secretkey={secretkey}
                    sitekey={sitekey}                    
                    secretkey={secretkey}
                    // secretkey="6LfXIzwiAAAAAPLQz-GBzSzX8joQJNs49oC0zGB6"
                    // secretkey="6LcD0FAiAAAAACkHeOJaM-ujAlByqtyvV1Vw8yTc"
                    onChange={handleContact}
                  />
                  {/* } */}
                </div>
                <div className=" flex space-x-4 justify-center">
                  <button
                    disabled={false}
                    className="contact_form_submitButton"
                    // id="contact_form_submitButton"
                    style={{fontFamily:"NunitoSans-Regular"}}
                    onClick={submitContactInfo}
                  >
                    {t('contact_submit_button')}
                  </button>
                  {/* <Button variant="success" onClick={initModal}>
                    Open Modal
                  </Button> */}
                </div>
              </div>
            </div>
            {/* <!-- Modal --> */}
          <Modal show={isShow}>
                  <Modal.Body>
                    <div className="modal_body">
                      <img className="tower" alt="building" src={tower} />
                      <div className="modal_content_2">
                      <h1 className="modal_main_head" style={{fontFamily:"Exo2-Bold"}}>{t('contact_thanks_text')}</h1>
                      <p className="modal_p" style={{fontFamily:"NunitoSans-Regular"}}>{t('contact_msgSent_text')}</p>
                      <Link style={{ all: "unset" }} to="/">
                      <button
                        className="contact_form_submitButton"
                        style={{fontFamily:"NunitoSans-Regular"}}
                        // onClick={submitContactInfo}
                      >
                          {t('contact_backtohome_text')}
                      </button>
                      </Link>
                      </div>
                      <span className="modalclosebtn" onClick={closeModal}>X</span>
                    </div>
                  </Modal.Body>
                </Modal>
        </div>

        <div className="row contact_mobile_view">
            <div className="contact__leaf__info" style={{width:'100%', textAlign:'left', marginTop:'45px', marginLeft: '0px'}}>
                <h1
                  style={{ fontFamily: "Exo2-Bold" }}
                  className="contact_head"
                >
                  {t('contact_head')}
                </h1>
                <p style={{ fontFamily: "NunitoSans-Regular" }} className="leading-relaxed  contact_content">
                {t('contact__content')}
                </p>
             </div>

             <div className="rounded-lg flex flex-col md:ml-auto w-full mt-10 md:mt-0 contact__leaf__form" style={{width: '100%'}}>
                <p className="contact_form_meeting_font tracking-widest" style={{ fontFamily: "Exo2-Regular" }}>{t('contact_form_meeting')}</p>
                <div className="flex text-[12px] space-x-4 contact_form_meeting_lang_mobile" >
                  <div style={{display:'flex', alignItems:'center'}}>
                    <input className="contact_form_checkbox" checked={en} onChange={changeLangEn} id="language" value="en"  type="checkbox" />
                    <label className="contact_form_lang_font">English</label>
                  </div>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <input className="contact_form_checkbox" checked={de} onChange={changeLangDe} id="language" value="de" type="checkbox" />
                    <label className="contact_form_lang_font">German</label>
                  </div>
                </div>
                <div className="relative text-left">
                  <label for="name" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                    Name*
                  </label>
                  <input
                    type="text"
                    id="mob_name"
                    name="name"
                    placeholder= {t('contact_name_text')}
                    className="contact_form_longInput "
                  />
                  <p id="mob_name_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Required</p>
                </div>
                <div className="relative text-left">
                  <label for="job_title" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                  {t('contact_subhead_position')}
                  </label>
                  <input
                    type="text"
                    id="mob_job_title"
                    name="Job Title"
                    placeholder= {t('contact_job_text')}
                    className="contact_form_longInput "
                  />
                  <p id="mob_job_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Required</p>
                </div>
                {/* <div className="flex space-x-4"> */}
                  <div className="relative text-left">
                    <label for="email" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                      Email*
                    </label>
                    <input
                      type="email"
                      id="mob_email"
                      name="email"
                      placeholder= {t('contact_email_text')}
                      className="contact_form_shortInput "
                    />
                  <p id="mob_email_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Please enter a valid email address</p>
                  </div>
                  <div className="relative text-left">
                    <label for="company" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                    {t('contact_subhead_company')}
                    </label>
                    <input
                      type="text"
                      id="mob_company"
                      name="company"
                      placeholder= {t('contact_company_text')}
                      className="contact_form_shortInput "
                    />
                  </div>
                {/* </div> */}
                <div className="relative text-left"  style={{ display: 'flex', flexDirection: 'column' }}>
                  <label for="phone_no" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                  {t('contact_subhead_number')}
                  </label>
                  <input
                    type="text"
                    id="mob_phone_no"
                    name="phone_no"
                    placeholder="+49"
                    className="contact_form_phoneInput "
                  />
                </div>
                <div className="relative text-left">
                  <label for="short_note" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                  {t('contact_subhead_note')}
                  </label>
                  <textarea
                    className="contact_form_shortnote"
                    id="mob_short_note"
                    name="mob_short_note"
                    rows="2"
                    cols="50"
                    placeholder={t('contact_note_text')}
                  />
                </div>
                <div className="flex my-2 space-x-4 text-left contact_form_Iagree_div" >
                <input
                    type="checkbox"
                    id="mob_iagree"
                    name="iagree"
                    onChange={iAgree}
                    // checked={agree}
                    onChange={handleMobSubmitbtn}
                    className="contact_form_checkbox bg-white "
                  />
                  {t('model_service_contact') === "Contact us" ? 
                  <p className="text-[12px] contact_form_Iagree"  style={{fontFamily:"NunitoSans-Regular"}}>
                    I agree to Leaftech’s <Link className="contact_form_Iagree_link" target="_blank"  to="/PrivacyPolicy">terms & conditions.</Link>
                  </p>
                  :
                   <p className="text-[12px] contact_form_Iagree"  style={{fontFamily:"NunitoSans-Regular"}}>
                     Ich stimme der <Link className="contact_form_Iagree_link" target="_blank"  to="/PrivacyPolicy">Datenschutzrichtlinie</Link> von Leaftech.
                   </p>
                  }
                </div>

                <div className="my-5  text-left">
                  <div className="space-y-3">
                    <p className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>{t('contact_subhead1')}</p>
                    <p id="mob_intrested_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Please select at least one title from the category.</p>
                  
                    <div className="flex space-x-3" style={{flexDirection:'row', alignItems:'center'}}>
                      <div className="flex space-x-3" style={{flexDirection:'column', width:'100%'}}>
                        {mobinterestedIN.map((item, index)=>{
                          return(
                            <button key={`${item.name}_${item.isActive}_${index}`} style={{ fontFamily: "Exo2-Regular", width:'100%', marginBottom:'8px', marginLeft:'0px' }}
                        className={item.isActive ? 'main': 'contact_subhead_buttons_text'} 
                        onClick={()=>mobtoggleClassInt(item)}  
                        >
                        {item.name}
                        </button>
                          )
                        })}

                      </div>
                      
                    <img className="building_img"  alt="relaxed" src={building} style={{width: '60% !important'}} />
                
                    </div>
                  </div>
                  <div className="space-y-3" style={{marginTop:'32px'}}>
                    <p className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>{t('contact_subhead2')}</p>
                    <p id="mob_represent_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Please select at least one title from the category.</p>
                    <div className="" style={{width:'100%'}}>
                      <div className="flex" style={{display:'flex', flexWrap:'wrap', marginLeft:'-15px'}}>

                        {mobrepresent.map((item, index)=>{
                          return(
                            <button key={`${item.name}_${item.isActivetoo}_${index}`} style={{ fontFamily: "Exo2-Regular", marginLeft:'8px', marginTop:'8px' }}
                                className={item.isActivetoo ? 'main': 'contact_subhead_buttons_text'} 
                                onClick={()=>mobtoggleClassRep(item)}  
                                >
                                {item.name}
                            </button>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className="flex space-x-4 captcha" style={{marginTop:'20px'}}>
                {/* {sitekey && sitekey?.length > 0 &&     */}
                  <ReCAPTCHA
                    // sitekey="6LfXIzwiAAAAADDM5qaC8OamV0s9iFEEAJz7bFQk"
                    // sitekey="6LcD0FAiAAAAAF0Kl22zxWxQ8lOccH1etbsiogNR"
                    // sitekey={url.includes('leaftech.de') ?  '6LewlZkiAAAAALm-9cARtX9zjhlw4o_n8H5-NywQ' : url.includes('leaftech.eu') ?  '6LdGk5kiAAAAAD1lVZrTuQtyBFZgXySJUIyw0XpT' : url.includes('websitetest.leaftech') ?  '6LfXIzwiAAAAADDM5qaC8OamV0s9iFEEAJz7bFQk' : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}                                       
                    // secretkey={secretkey}
                    sitekey={sitekey}                    
                    secretkey={secretkey}
                    // secretkey="6LfXIzwiAAAAAPLQz-GBzSzX8joQJNs49oC0zGB6"
                    // secretkey="6LcD0FAiAAAAACkHeOJaM-ujAlByqtyvV1Vw8yTc"
                    onChange={handleContact}
                  />
                  {/* } */}
                </div>
                <div className=" flex  justify-left" style={{marginBottom:'60px'}}>
                  <img className="usersit_img"  alt="relaxed" src={relaxed} />
                  <button
                    disabled={false}
                    className="contact_form_submitButton"
                    // id="contact_form_submitButton"
                    style={{fontFamily:"NunitoSans-Regular"}}
                    onClick={mobSubmitContactInfo}
                  >
                    {t('contact_submit_button')}
                  </button>
                  {/* <Button variant="success" onClick={initModal}>
                    Open Modal
                  </Button> */}
                </div>
              </div>


        </div>

        {/* tab view */}

        <div className="row contact_tab_view" >
            <div  className="flex-col contact__leaf-wrapper">
              <div className="contact__leaf__info" >
                <h1
                  style={{ fontFamily: "Exo2-Bold" }}
                  className="contact_head"
                >
                  {t('contact_head')}
                </h1>
                <p style={{ fontFamily: "NunitoSans-Regular" }} className="leading-relaxed  contact_content">
                {t('contact__content')}
                </p>
                <div className="my-5  text-left">
                  <div className="space-y-3">
                    <h3 className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>{t('contact_subhead1')}</h3>
                    <p id="tab_intrested_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Please select at least one title from the category.</p>
                  
                    <div className="flex space-x-3">
                      {tabinterestedIN.map((item, index)=>{
                        return(
                          <button key={`${item.name}_${item.isActive}_${index}`} style={{ fontFamily: "Exo2-Regular" }}
                      className={item.isActive ? 'main': 'contact_subhead_buttons_text'} 
                      onClick={()=>tabtoggleClassInt(item)}  
                      >
                      {item.name}
                      </button>
                        )
                      })}

                    </div>
                  </div>
                  <div className="space-y-3" style={{marginTop:'32px'}}>
                    <p className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>{t('contact_subhead2')}</p>
                    <p id="tab_represent_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Please select at least one title from the category.</p>
                    <div className="space-x-4 space-y-4" style={{width:'705px'}}>
                      <div className="flex" style={{display:'flex', flexWrap:'wrap', marginLeft:'-15px'}}>

                        {tabrepresent.map((item, index)=>{
                          return(
                            <button key={`${item.name}_${item.isActivetoo}_${index}`} style={{ fontFamily: "Exo2-Regular", marginLeft:'16px', marginTop:'16px' }}
                                className={item.isActivetoo ? 'main': 'contact_subhead_buttons_text'} 
                                onClick={()=>tabtoggleClassRep(item)}  
                                >
                                {item.name}
                            </button>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  {t('contact_subhead_buttons_text9')==="Others" ?
                    <div className="">
                      <div className="align-self-end">
                      <img className="usersit_img"  alt="relaxed" src={relaxed} />
                    </div>
                    <div className="justify-end w-full">
                      <img className="building_img" alt="building" src={building} />
                    </div>
                </div>
                  :
                  <div className="">
                    <div className="align-self-end">
                    <img className="usersit_img_de"  alt="relaxed" src={relaxed} />
                  </div>
                  <div className="justify-end w-full">
                    <img className="building_img_de" alt="building" src={building} />
                  </div>
                  
                  </div>
                      }
                </div>
              </div>
              <div className="contact__leaf__form" >
                <div className="flex justify-end text-[12px] space-x-4" style={{alignItems:'center', marginBottom:'24px', marginTop:'35px'}}>
                  <p className="contact_form_meeting_font tracking-widest" style={{ fontFamily: "Exo2-Regular" }}>{t('contact_form_meeting')}</p>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <input className="contact_form_checkbox" checked={en} onChange={changeLangEn} id="language" value="en"  type="checkbox" />
                    <label className="contact_form_lang_font">English</label>
                  </div>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <input className="contact_form_checkbox" checked={de} onChange={changeLangDe} id="language" value="de" type="checkbox" />
                    <label className="contact_form_lang_font">German</label>
                  </div>
                </div>
                <div className="relative text-left">
                  <label for="name" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                    Name*
                  </label>
                  <input
                    type="text"
                    id="tab_name"
                    name="name"
                    placeholder= {t('contact_name_text')}
                    className="contact_form_longInput drop-shadow-sm bg-white rounded-[10px] border  text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                  <p id="tab_name_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Required</p>
                </div>
                <div className="relative text-left">
                  <label for="job_title" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                  {t('contact_subhead_position')}
                  </label>
                  <input
                    type="text"
                    id="tab_job_title"
                    name="Job Title"
                    placeholder= {t('contact_job_text')}
                    className="contact_form_longInput drop-shadow-sm bg-white rounded-[10px] border  text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                  <p id="tab_job_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Required</p>
                </div>
                <div className="flex space-x-4">
                  <div className="relative text-left">
                    <label for="email" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                      Email*
                    </label>
                    <input
                      type="email"
                      id="tab_email"
                      name="email"
                      placeholder= {t('contact_email_text')}
                      className="contact_form_shortInput drop-shadow-sm bg-white rounded-[10px] border  text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  <p id="tab_email_error_text" style={{display:'none', fontFamily: "NunitoSans-Regular"}}>Please enter a valid email address</p>
                  </div>
                  <div className="relative text-left">
                    <label for="company" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                    {t('contact_subhead_company')}
                    </label>
                    <input
                      type="text"
                      id="tab_company"
                      name="company"
                      placeholder= {t('contact_company_text')}
                      className="contact_form_shortInput drop-shadow-sm bg-white rounded-[10px] border  text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="relative text-left"  style={{ display: 'flex', flexDirection: 'column' }}>
                  <label for="phone_no" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                  {t('contact_subhead_number')}
                  </label>
                  <input
                    type="text"
                    id="tab_phone_no"
                    name="phone_no"
                    placeholder="+49"
                    className="contact_form_phoneInput drop-shadow-sm bg-white rounded-[10px] border  text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <div className="relative text-left">
                  <label for="short_note" className="contact_subhead" style={{ fontFamily: "NunitoSans-Regular" }}>
                  {t('contact_subhead_note')}
                  </label>
                  <textarea
                    className="contact_form_shortnote"
                    id="tab_short_note"
                    name="short_note"
                    rows="4"
                    cols="50"
                    placeholder={t('contact_note_text')}
                  />
                </div>
                <div className="flex my-2 space-x-4 text-left contact_form_Iagree_div" >
                  <input
                    type="checkbox"
                    id="tab_iagree"
                    name="iagree"
                    onChange={iAgree}
                    // checked={agree}
                    onChange={handleTabSubmitbtn}
                    className="contact_form_checkbox bg-white "
                  />
                 
                  {t('model_service_contact') === "Contact us" ? 
                  <p className="text-[12px] contact_form_Iagree"  style={{fontFamily:"NunitoSans-Regular"}}>
                    I agree to Leaftech’s <Link className="contact_form_Iagree_link" target="_blank"  to="/PrivacyPolicy">terms & conditions.</Link>
                  </p>
                  :
                   <p className="text-[12px] contact_form_Iagree"  style={{fontFamily:"NunitoSans-Regular"}}>
                     Ich stimme der <Link className="contact_form_Iagree_link" target="_blank"  to="/PrivacyPolicy">Datenschutzrichtlinie</Link> von Leaftech.
                   </p>
                  }
                </div>
                <div className="flex space-x-4 captcha" style={{marginTop:'20px'}}>
                {/* {sitekey && sitekey?.length > 0 &&     */}
                <ReCAPTCHA
                  // sitekey="6LfXIzwiAAAAADDM5qaC8OamV0s9iFEEAJz7bFQk"
                  // sitekey="6LcD0FAiAAAAAF0Kl22zxWxQ8lOccH1etbsiogNR"
                  // sitekey={url.includes('leaftech.de') ?  '6LewlZkiAAAAALm-9cARtX9zjhlw4o_n8H5-NywQ' : url.includes('leaftech.eu') ?  '6LdGk5kiAAAAAD1lVZrTuQtyBFZgXySJUIyw0XpT' : url.includes('websitetest.leaftech') ?  '6LfXIzwiAAAAADDM5qaC8OamV0s9iFEEAJz7bFQk' : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}                                        
                  // secretkey={secretkey}
                  sitekey={sitekey}                    
                  secretkey={secretkey}
                  // secretkey="6LfXIzwiAAAAAPLQz-GBzSzX8joQJNs49oC0zGB6"
                  // secretkey="6LcD0FAiAAAAACkHeOJaM-ujAlByqtyvV1Vw8yTc"
                  onChange={handleContact}
                />
    {/* } */}
                </div>
                <div className=" flex space-x-4 justify-center">
                  <button
                    disabled={false}
                    className="contact_form_submitButton"
                    // id="contact_form_submitButton"
                    style={{fontFamily:"NunitoSans-Regular"}}
                    onClick={submitTabContactInfo}
                  >
                    {t('contact_submit_button')}
                  </button>
                  {/* <Button variant="success" onClick={initModal}>
                    Open Modal
                  </Button> */}
                </div>
              </div>
            </div>
            {/* <!-- Modal --> */}
          <Modal show={isShow}>
                  <Modal.Body>
                    <div className="modal_body">
                      <img className="tower" alt="building" src={tower} />
                      <div className="modal_content_2">
                      <h1 className="modal_main_head" style={{fontFamily:"Exo2-Bold"}}>{t('contact_thanks_text')}</h1>
                      <p className="modal_p" style={{fontFamily:"NunitoSans-Regular"}}>{t('contact_msgSent_text')}</p>
                      <Link style={{ all: "unset" }} to="/">
                      <button
                        className="contact_form_submitButton"
                        style={{fontFamily:"NunitoSans-Regular"}}
                        // onClick={submitContactInfo}
                      >
                          {t('contact_backtohome_text')}
                      </button>
                      </Link>
                      </div>
                      <span className="modalclosebtn" onClick={closeModal}>X</span>
                    </div>
                  </Modal.Body>
                </Modal>
        </div>
    
      </div>
    </div>




    

    </div>

  );
}


