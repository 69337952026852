import React from "react";
import Stepper from "./stepper";


function mann3() {
  return (
    <div className="container">
      <div className="text-gray-600 body-font">
            <div className="steeper">
              <Stepper />
              {/* <div className="flex relative pb-12">
                <div className="flex-grow pl-4">
                  <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    01. Digital Twin Creation
                  </h2>
                </div>
              </div>
              <div className="flex relative pb-12">
                <div className="flex-grow pl-4">
                  <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    02. Building and Weather Integration
                  </h2>
                </div>
              </div>
              <div className="flex relative pb-12">
                <div className="flex-grow pl-4">
                  <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    03. Energy Balance Calculation
                  </h2>
                </div>
              </div>
              <div className="flex relative pb-12">
                <div className="flex-grow pl-4">
                  <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    04. Cooling load assessment
                  </h2>
                </div>
              </div>
              <div className="flex relative">
                <div className="flex-grow pl-4">
                  <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    05. Optimization Recommendations
                  </h2>
                </div>
              </div> */}
            </div>
            {/* <div className="lg:w-3/5 md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12">
              <p>Digital Twin Creation</p>
              <img
                className="lg:w-3/5 md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12"
                src="https://dummyimage.com/1200x500"
                alt="step"
              />
            </div> */}
          </div>
        </div>
      
  );
}

export default mann3;
