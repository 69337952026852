import styled from '@emotion/styled'

const mobileBreak = '601px'

export const Button = styled.button`
  padding: 8px;
  border-radius: 20px;
  transition: all ease-in-out 0.15s;
  font-size: 1rem;
  width: 8em;

  &:hover {
    box-shadow: 0px 4px 4px #0087ff3f;
  }

  @media (width < ${mobileBreak}) {
    font-size: 0.85rem;
    width: 8em;
  }

  ${({ variant, color }) =>
    variant === 'outlined'
      ? `
    border: 1px solid ${color};
    background-color: transparent;
    color: ${color}
    `
      : variant === 'contained'
      ? `
        background-color: ${color};
        color: white;
        `
      : ``}
`
