import styled from '@emotion/styled'

const mobileBreak = '601px'

const tabletBreak = '1001px'

export const Container = styled.div`
  padding: 0 4em;
  margin: 7em auto 1em auto;

  @media (max-width: ${tabletBreak}) {
    padding: 0;
    margin: 2em auto 0 auto;
  }
`

export const TitleRow = styled.div`
  text-align: left;

  @media (max-width: ${mobileBreak}) {
    text-align: center;
  }
`

export const Title = styled.h2`
  font-family: 'Exo2-bold';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: #2f2f2f;
  text-align: center;

  @media (width < ${mobileBreak}) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const CarouselWrapper = styled.section`
  max-width: 95%;
  margin: 2em auto;

  @media (width < ${tabletBreak}) {
    max-width: 100%;
    width: 100%;
  }

  & > div {
    padding: 0 5em;

    @media (width < ${tabletBreak}) {
      width: 90vw;
      padding: 0;
      margin: 2em auto;
    }

    @media (width < ${mobileBreak}) {
      width: 90vw;
      padding: 0;
      margin: 2em auto;
    }
  }
`

export const Card = styled.div`
  border: 1px solid #c7c8ce;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 556px;
  padding: 1.5rem 2rem;

  @media (max-width: ${tabletBreak}) {
    height: 550px;
    width: 100%;
    padding: 1rem 2rem;
  }

  @media (max-width: ${mobileBreak}) {
    height: 408px;
    width: 100%;
    padding: 1rem;
  }
`

export const QuoteText = styled.p`
  font-family: 'Nunito Sans';
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.05em;
  color: #2f2f2f;
  text-align: left;
  margin-bottom: 2em;

  @media (max-width: ${tabletBreak}) {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.05em;
  }

  @media (max-width: ${mobileBreak}) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
  }
`

export const CustomerName = styled.b`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #0087ff;
  text-align: left;

  @media (max-width: ${mobileBreak}) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    font-weight: 600;
  }
`

export const CustomerPosition = styled.p`
  color: #2f2f2f;
  font-family: 'Nunito Sans';
  font-style: bold;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-align: left;

  @media (max-width: ${mobileBreak}) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
  }
`
