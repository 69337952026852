import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Routes from "./Routes/index";
import reportWebVitals from "./reportWebVitals";
import "./translator";
import { HelmetProvider } from "react-helmet-async";
import CookieCard from "./components/CookieCard/CookieCard";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      {/* <Suspense fallback={null}> */}
      <Routes />
      <CookieCard />
      {/* </Suspense> */}
    </HelmetProvider>
  </React.StrictMode>
);
console.log(process.env.REACT_APP_GOOGLE_ANALYTICS)
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
};
reportWebVitals(SendAnalytics);
