import { Button } from './flatButtton_styled'

const FlatButton = ({ variant, color, label, onClick }) => {
  return (
    <>
      <Button onClick={onClick} variant={variant} color={color}>
        {label}
      </Button>
    </>
  )
}

export default FlatButton
