import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import Grune1 from './GA1'
import Grune2 from './GA2'
import Grune3 from './GA3'
import Grune4 from './GA4'
import Grune5 from '../Mannheim/mann5'
import Grune6 from './GA6'
import { Helmet } from 'react-helmet-async'

export default function indexs() {
  return (
    <>
      <Helmet>
        <link rel='alternate' href='https://leaftech.eu/success-stories/gruene-aue' hreflang='en' />
        <link rel='alternate' href='https://leaftech.eu/de/erfolgsgeschichten/gruene-aue' hreflang='de' />
      </Helmet>

      <div className='App'>
        <div className=''>
          <Header />
          <Grune1 />
          <Grune2 />
          <Grune3 />
          <Grune4 />
          <Grune5 />
          <Grune6 />
          <Footer />
        </div>
      </div>
    </>
  )
}
