import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import PrivacyPolicy from "./PrivacyPolicy";

export default function index() {
  return (
    <div className="App" >
      <div className="">
       <Header />
        <PrivacyPolicy />
        <Footer />
      </div>
    </div>
  );
}
