
import React from "react";
import Stepper from "./AnalyticsStepper";


function index() {
  return (
    <div className="container">
      <div className="row">
        <Stepper />
      </div>
    </div>
  );
}

export default index;