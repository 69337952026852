import styled from '@emotion/styled'

const mobile = '850px'

export const Backdrop = styled.div`
  @media (width < ${mobile}) {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    backdrop-filter: blur(10px);
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 32px;
  background: #ffffff;
  border: 1px solid #c7c8ce;
  border-radius: 10px;
  width: 428px;
  position: sticky;
  bottom: 2rem;
  left: 2rem;

  @media (width < ${mobile}) {
    width: 75vw;
    height: 275px;
    left: 13vw;
    bottom: 35vh;
  }
`

export const Title = styled.b`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #2f2f2f;
  margin-bottom: 1rem;
`

export const Text = styled.p`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #2f2f2f;

  a {
    font-size: 12px;
    font-weight: bold;
  }
`

export const ButtonsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;

  @media (width < ${mobile}) {
    justify-content: space-between;
  }
`
