import React from "react";
import img from "../assest/Images/home/Laptop.png";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';//translator

export default function GetStart() {
  const { t, i18n } = useTranslation();//translator
  return (
    <div style={{ backgroundColor: ' #FAFBFC', zIndex: '0', textAlign: 'left' }}>
      <div className="container">
        <div className='row access_your_container' >
          <div className="col-lg-1 col-md-0"></div>
          <div className="col-lg-5 col-md-6 access_your_content_container">
            <h3 className="access_your_head access_your_head_desktop title-font mb-4" style={{ fontFamily: "Exo2-Bold" }}>
              {t('Home_section9_head_a')} <br />
              {t('Home_section9_head_b')}
            </h3>
            <h3 className="access_your_head access_your_head_tab title-font mb-4" style={{ fontFamily: "Exo2-Bold" }}>
              {t('Home_section9_head_a')}  {t('Home_section9_head_b')}
            </h3>
            <p className="access_your_content access_your_head_desktop" style={{ fontFamily: "NunitoSans-Regular" }}>
              {t('Home_section9_content')}
            </p>
            <p className="access_your_content access_your_head_tab" style={{ fontFamily: "NunitoSans-Regular" }}>
              {t('Home_section9_tabcontent1')}<br />
              {t('Home_section9_tabcontent2')}
            </p>
            <div >
            {t('model_service_contact') === "Contact us" ?
              <Link style={{ all: "unset" }} to="/contact">
                <button
                  style={{
                    fontFamily: "NunitoSans-Regular",
                    marginTop: '64px'
                  }}
                  className="contact_us"
                >
                  {t('model_service_contact')}
                </button>
                {/* <button style={{marginTop:'64px', fontSize:'20px'}} className="inline-flex text-white py-2 px-6 focus:outline-none bg-[#0087FF] shadow-[0_4px_4px_rgba(0,135,255,0.25)] rounded-[20px] text-lg">
              {t('model_service_contact')}
              </button> */}
              </Link>
              :
              <Link style={{ all: "unset" }} to="/de/kontakt">
                <button
                  style={{
                    fontFamily: "NunitoSans-Regular",
                    marginTop: '64px'
                  }}
                  className="contact_us"
                >
                  {t('model_service_contact')}
                </button>
                {/* <button style={{marginTop:'64px', fontSize:'20px'}} className="inline-flex text-white py-2 px-6 focus:outline-none bg-[#0087FF] shadow-[0_4px_4px_rgba(0,135,255,0.25)] rounded-[20px] text-lg">
              {t('model_service_contact')}
              </button> */}
              </Link>
            }
            </div>
          </div>
          <div className="col-lg-5 col-md-6 access_your_img_container">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={img}
            />
          </div>
          <div className="col-lg-1 col-md-0"></div>
        </div>
      </div>
    </div>
  );
}
