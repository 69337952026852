import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import React from "react";
import AServices from "../components/Services/Analytics/ServicesAnalytis";
import CServices from "../components/Services/Control/ServicesControl";
import MServices from "../components/Services/Modelling/ServicesModeling";
import Home from "../App";
import LegalInformation from "../components/legalInformation/index";
import Project from "../components/Project/Project";
import Contact from "../components/Contact/Contact";
// eslint-disable-next-line no-unused-vars
// import legalInformation from "../components/legalInformation/legalInformation";
import Mannhiem from "../components/Project/Mannheim/index";
import Granue from "../components/Project/GruneAue/indexs";
import Wago from "../components/Project/Wago/index";
import Phoenix from "../components/Project/Phoenix/index";
import AboutUsMain from "../components/AboutUs/AboutUsMain";
import Careers from "../components/Careers/index";
import PositionOne from "../components/Careers/PositionOne";
import PositionTwo from "../components/Careers/PositionTwo";
import PrivacyPolicy from "../components/PrivacyPolicy/index";




export default function index() {

  
  return (
    <div>
      <Router>
        <Routes>
          {/* <Route path="/" element={<Layout />} /> */}
          <Route path="/" exact element={<Home />} />
          <Route path="/de/" exact element={<Home />} />
          <Route path="success-stories" element={<Project />} />
          <Route path="/de/erfolgsgeschichten" element={<Project />} />
          <Route path="/success-stories/trio-mannhiem" element={<Mannhiem />} />
          <Route path="/de/erfolgsgeschichten/trio-mannhiem" element={<Mannhiem />} />
          <Route path="/success-stories/gruene-aue" element={<Granue />} />
          <Route path="/de/erfolgsgeschichten/gruene-aue" element={<Granue />} />
          <Route path="/success-stories/wago-headquarters" element={<Wago />} />
          <Route path="/de/erfolgsgeschichten/wago-headquarters" element={<Wago />} />
          <Route path="/success-stories/phoenix-contact" element={<Phoenix />} />
          <Route path="/de/erfolgsgeschichten/phoenix-contact" element={<Phoenix />} />

          <Route path="/legal-information" element={<LegalInformation />} />
          <Route path="/de/impressum" element={<LegalInformation />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/de/datenschutzerklärung" element={<PrivacyPolicy />} />
          <Route path="about-us" element={<AboutUsMain />} />
          <Route path="/de/ueber-uns" element={<AboutUsMain />} />
          <Route path="careers" element={<Careers />} />
          <Route path="/de/karriere" element={<Careers />} />
          <Route path="careers/head-of-sales" element={<PositionOne />} />
          <Route path="/de/karriere/head-of-sales" element={<PositionOne />} />
          <Route path="careers/technical-sales-executive" element={<PositionTwo />} />
          <Route path="/de/karriere/technical-sales-executive" element={<PositionTwo />} />
         

          <Route path="/services/building-analytics" element={<AServices />} />
          <Route path="/de/leistungen/gebaeudeanalysen" element={<AServices />} />
          <Route path="/services/smart-building-control" element={<CServices />} />
          <Route path="/de/leistungen/intelligente-gebaeudesteuerung" element={<CServices />} />
          <Route path="/services/3d-digital-models-of-buildings" element={<MServices />} />
          <Route path="/de/leistungen/gebaeudedigitalisierung" element={<MServices />} />

          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/de/kontakt" element={<Contact />} />
          {/* <Route path="/#building-owners" element={<Home />} /> */}
          
        </Routes>
      </Router>
    </div>
  );
}
