import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import AboutUs from './AboutUs'
import { Helmet } from 'react-helmet-async'

export default function AboutUsMain() {
  return (
    <>
      <Helmet>
        <link rel='alternate' href='https://leaftech.eu/about-us' hreflang='en' />
        <link rel='alternate' href='https://leaftech.eu/de/ueber-uns' hreflang='de' />
      </Helmet>

      <div className='App'>
        <div className='project__wrapper'>
          <Header />
          <AboutUs />
          <Footer />
        </div>
      </div>
    </>
  )
}
