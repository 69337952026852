import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';//translator
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Line from "./../../assest/about&careers/Line 8.png"
import Michael from "./../../assest/about&careers/Micha.png"
// service@leaftech.eu

export default function PositionTwo() {
  const { t, i18n } = useTranslation();//translator
  
  // function emailClient() {
  //   console.log('hi')
  //   window.location = "mailto:service@leaftech.eu";
  // }

  return (
    <div>
      <Header />
       <div className="aboutus_landing_toprow">
        <div className="container">
          <div className="row">
          <div className="position_first_content_block"  >
            <span
              style={{ fontFamily: "Exo2-SemiBold", marginBottom: '16px' }}
              className="position_blueHead"
              >
              {/* Projects */}
              {t('position2_mainBlue_head')}
              </span>
              <h1
                style={{ fontFamily: "Exo2-Bold" }}
                className="position_mainHead "
              >
              {t('position2_mainhead')}
              </h1>
              <span
              style={{ fontFamily: "Exo2-SemiBold", marginBottom: '16px' }}
              className="position_subblueHead"
              >
              {/* Projects */}
              {t('position2_subBlue_head')}
              </span>
              <div style={{width:'100%', display:'flex'}} >
                  <div className="" style={{display:'flex', flexDirection:'column'}}>
                    <h1 style={{ fontFamily: "Exo2-SemiBold"}} className="positionFirst_content_subhead"> {t('position2_experience')}</h1>
                    <p className="positionFirst_content_subcontent" style={{ fontFamily: "NunitoSans-Regular"}}>
                      {t('position2_experience_content')}
                    </p>
                  </div>

                  <div className="positionFirst_content_middle_subcontent" >
                    <h1 style={{ fontFamily: "Exo2-SemiBold"}} className="positionFirst_content_subhead"> {t('position2_location')}</h1>
                    <p className="positionFirst_content_subcontent" style={{ fontFamily: "NunitoSans-Regular"}}>
                      {t('position2_location_content')}
                    </p>
                  </div>

                  <div className="" style={{display:'flex', flexDirection:'column',}}>
                  <h1 style={{ fontFamily: "Exo2-SemiBold"}} className="positionFirst_content_subhead"> {t('position2_employment_type')}</h1>
                    <p className="positionFirst_content_subcontent" style={{ fontFamily: "NunitoSans-Regular"}}>
                      {t('position2_employment_type_content')}
                    </p>
                  </div>
              </div>
          </div>
          </div>
        </div>
       </div> 
 
       <div className="container">   
        <div className="row">
          <div className="position_second_content_block">
            <h1 style={{ fontFamily: "Exo2-Bold" }} className="position_about_leaftech_head">{t('position2_subhead1')}</h1>
            <p style={{ fontFamily: "NunitoSans-Regular" }} className="position_about_leaftech_content">{t('position2_subcontent11')}</p>
            <p style={{ fontFamily: "NunitoSans-Regular" }} className="position_about_leaftech_content">{t('position2_subcontent12')}</p>
            <p style={{ fontFamily: "NunitoSans-Regular" }} className="position_about_leaftech_content">{t('position2_subcontent13')}</p>
            <p style={{ fontFamily: "NunitoSans-Regular" }} className="position_about_leaftech_content">{t('position2_subcontent14')}</p>
          </div>   
        </div>

        <div className="row">
          <div className="position_third_content_subcontent">
            <h1 style={{ fontFamily: "Exo2-Bold" }} className="position_about_leaftech_head">{t('position2_subhead2')}</h1>
            
            <div className="row responsibility_and_expect">
              <div className="resEexpect_content_block ">
                <div className="p2_sideline_ul1"></div>
                <ul className="position_resExpect_ul">
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent21')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent22')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent23')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent24')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent25')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent26')}</li>
                  
                </ul>
              </div>
             
              <div className="resEexpect_content_block ">
                <div className="p2_sideline_ul2"></div>
                <ul className="position_resExpect_ul">
                <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent27')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent28')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent29')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent220')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent221')}</li>
                </ul>
              </div>
          
            </div>
        
          </div>   
        </div>

        <div className="row">
          <div className="position_fourth_content_block">
            <h1 style={{ fontFamily: "Exo2-Bold" }} className="position_about_leaftech_head">{t('position2_subhead3')}</h1>
            
            <div className="row responsibility_and_expect">
              <div className="resEexpect_content_block ">
                <div  className="p2_sideline_ul3"></div>
                <ul className="position_resExpect_ul">
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent31')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent32')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent33')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent34')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent35')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent36')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent37')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent38')}</li>
                </ul>
              </div>
             
              <div className="resEexpect_content_block ">
                <div  className="p2_sideline_ul4"></div>
                <ul className="position_resExpect_ul">
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent39')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent320')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent321')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent322')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent323')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent324')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_resExpect_li">{t('position2_subcontent325')}</li>
                </ul>
              </div>
          
            </div>
        
          </div>   
        </div>

        <div className="row">
          <div className="position_fifth_content_block">
            <h1 style={{ fontFamily: "Exo2-Bold" }} className="position_about_leaftech_head">{t('position2_subhead4')}</h1>
            <div className="row weOffer">
              <div className="weOffer_content_block col-12">
                <div className="weOffer_sideline"></div>
                <ul className="position_weOffer_ul">
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_weOffer_li">{t('position2_subcontent41')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_weOffer_li">{t('position2_subcontent42')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_weOffer_li">{t('position2_subcontent43')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_weOffer_li">{t('position2_subcontent44')}</li>
                  <li style={{ fontFamily: "NunitoSans-Regular" }} className="position_weOffer_li">{t('position2_subcontent45')}</li>
                </ul>
              </div>
             
            </div>
          </div>   
        </div>

        <div className="row">
          <div className="position_sixth_content_block">
            <div className="moreInfo_container">
              <p style={{ fontFamily: "NunitoSans-Regular" }} className="position_moreInfo">{t('position2_moreInfoContent')}</p>
              <div className="flex">
              <button
                  style={{
                    fontFamily: "NunitoSans-Regular",
                  }}
                  className="contact_us"
                  onClick={() => window.location = "mailto:service@leaftech.eu"}
                >
                  {t('position1_moreInfo_btn')}
                </button>
              </div>
            </div>
            <div className="position_moreInfo_img_container rounded-lg overflow-hidden">
            <img
              alt="feature"
              className="responsive position_moreInfo_img"
              src={Michael}
            />
          </div>
          </div>   
        </div>

      </div>
      <Footer />
    </div>
  )
}
