import React from 'react'
import { useTranslation } from 'react-i18next' //translator
import { Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { List, ListItem, SubContentList } from './privacy_styles'

export default function PrivacyPolicy() {
  const { t, i18n } = useTranslation() //translator
  return (
    <div className='row'>
      <div className='row privacy_landing_toprow'>
        <div className='container'>
          <div className='privacy_head'>
            <h1 style={{ fontFamily: 'Exo2-Bold' }} className='single_project_main_head '>
              {t('privacy_mainhead')}
            </h1>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='privacySubContent_row mb-20'>
          <List>
            <ListItem>{t('privacy_chapter_1')}</ListItem>

            <h2 className='privacy_subhead'>{t('privacy_subhead1')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent11')}</p>

            <h2 className='privacy_subhead'>{t('privacy_subhead2')}</h2>
            <p className='privacy_subContent'>
              <b>{t('privacy_subcontent_bold21')}</b>
            </p>
            <p className='privacy_subContent'>{t('privacy_subcontent21')}</p>
            <p className='privacy_subContent'>
              <b>{t('privacy_subcontent_bold22')}</b>
            </p>
            <p className='privacy_subContent'>{t('privacy_subcontent22')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent23')}</p>
            <p className='privacy_subContent'>
              <b>{t('privacy_subcontent_bold23')}</b>
            </p>
            <p className='privacy_subContent'>{t('privacy_subcontent24')}</p>
            <p className='privacy_subContent'>
              <b>{t('privacy_subcontent_bold24')}</b>
            </p>
            <p className='privacy_subContent'>{t('privacy_subcontent25')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent26')}</p>

            <h2 className='privacy_subhead'>{t('privacy_subhead3')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent31')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent32')}</p>

            <ListItem>{t('privacy_chapter_2')}</ListItem>

            <p className='privacy_subContent'>{t('privacy_subcontent_2_0')}</p>
            <h2 className='privacy_subhead'>{t('privacy_subhead_2_1')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_2_11')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_2_12')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_2_13')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_2_14')}</p>
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_2_15') }} />
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_2_16') }} />
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_2_17') }} />

            <h2 className='privacy_subhead'>{t('privacy_subhead_2_2')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_2_21')}</p>

            <ListItem>{t('privacy_chapter_3')}</ListItem>

            <h2 className='privacy_subhead'>{t('privacy_subhead_3_1')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_3_11')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_3_12')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_3_13')}</p>

            <h2 className='privacy_subhead'>{t('privacy_subhead_3_2')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_3_21')}</p>
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_3_22') }} />
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_3_23') }} />
            <p className='privacy_subContent'>{t('privacy_subcontent_3_24')}</p>

            <h2 className='privacy_subhead'>{t('privacy_subhead_3_3')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_3_31')}</p>
            <h2 className='privacy_subhead'>{t('privacy_subhead_3_4')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_3_41')}</p>
            <h2 className='privacy_subhead'>{t('privacy_subhead_3_5')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_3_51')}</p>
            <h2 className='privacy_subhead'>{t('privacy_subhead_3_6')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_3_61')}</p>
            <h2 className='privacy_subhead'>{t('privacy_subhead_3_7')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_3_71')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_3_72')}</p>
            <h2 className='privacy_subhead'>{t('privacy_subhead_3_8')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_3_81')}</p>
            <h2 className='privacy_subhead'>{t('privacy_subhead_3_9')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_3_91')}</p>
            <h2 className='privacy_subhead'>{t('privacy_subhead_3_10')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_3_101')}</p>
            <h2 className='privacy_subhead'>{t('privacy_subhead_3_11')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_3_111')}</p>
            <SubContentList className='privacy_subContent'>
              <li>{t('privacy_subcontent_3_112_li_1')}</li>
              <li>{t('privacy_subcontent_3_112_li_2')}</li>
              <li>{t('privacy_subcontent_3_112_li_3')}</li>
              <li>{t('privacy_subcontent_3_112_li_4')}</li>
            </SubContentList>
            <h2 className='privacy_subhead'>{t('privacy_subhead_3_12')}</h2>
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_3_121') }} />

            <ListItem>{t('privacy_chapter_4')}</ListItem>

            <h2 className='privacy_subhead'>{t('privacy_subhead_4_1')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_4_11')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_4_12')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_4_13')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_4_14')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_4_15')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_4_16')}</p>
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_4_17') }} />

            <h2 className='privacy_subhead'>{t('privacy_subhead_4_2')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_4_21')}</p>
            <SubContentList className='privacy_subContent'>
              <li>{t('privacy_subcontent_4_22_li_1')}</li>
              <li>{t('privacy_subcontent_4_22_li_2')}</li>
              <li>{t('privacy_subcontent_4_22_li_3')}</li>
              <li>{t('privacy_subcontent_4_22_li_4')}</li>
              <li>{t('privacy_subcontent_4_22_li_5')}</li>
              <li>{t('privacy_subcontent_4_22_li_6')}</li>
            </SubContentList>
            <p className='privacy_subContent'>{t('privacy_subcontent_4_23')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_4_24')}</p>

            <h2 className='privacy_subhead'>{t('privacy_subhead_4_3')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_4_31')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_4_32')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_4_33')}</p>

            <ListItem>{t('privacy_chapter_5')}</ListItem>

            <h2 className='privacy_subhead'>{t('privacy_subhead_5_1')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_11')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_12')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_13')}</p>

            <h2 className='privacy_subhead'>{t('privacy_subhead_5_2')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_21')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_22')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_23')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_24')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_25')}</p>
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_5_26') }} />
            <p className='privacy_subContent'>
              <b>{t('privacy_subcontent_5_27_bold')}</b>
            </p>
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_5_28') }} />
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_5_29') }} />
            <p className='privacy_subContent'>
              <b>{t('privacy_subcontent_5_210_bold')}</b>
            </p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_211')}</p>
            <p className='privacy_subContent'>
              <b>{t('privacy_subcontent_5_212_bold')}</b>
            </p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_213')}</p>

            <h2 className='privacy_subhead'>{t('privacy_subhead_5_4')}</h2>
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_5_41') }} />
            <p className='privacy_subContent'>{t('privacy_subcontent_5_42')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_43')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_44')}</p>
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_5_45') }} />
            <p className='privacy_subContent'>
              <b>{t('privacy_subcontent_5_46_bold')}</b>
            </p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_47')}</p>

            <h2 className='privacy_subhead'>{t('privacy_subhead_5_5')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_51')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_52')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_53')}</p>
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_5_54') }} />

            <h2 className='privacy_subhead'>{t('privacy_subhead_5_6')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_61')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_62')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_5_63')}</p>
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_5_64') }} />

            <ListItem>{t('privacy_chapter_6')}</ListItem>

            <h2 className='privacy_subhead'>{t('privacy_subhead_6_1')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_6_11')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_6_12')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_6_13')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_6_14')}</p>
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_6_15') }} />

            <ListItem>{t('privacy_chapter_7')}</ListItem>

            <h2 className='privacy_subhead'>{t('privacy_subhead_7_1')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_7_11')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_7_12')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_7_13')}</p>
            <p className='privacy_subContent'>{t('privacy_subcontent_7_14')}</p>

            <h2 className='privacy_subhead'>{t('privacy_subhead_7_2')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_7_21')}</p>
            <h2 className='privacy_subhead'>{t('privacy_subhead_7_3')}</h2>
            
            <p className='privacy_subContent'>{t('privacy_subcontent_7_31')}</p>
            <h2 className='privacy_subhead'>{t('privacy_subhead_7_4')}</h2>
            
            <p className='privacy_subContent'>{t('privacy_subcontent_7_41')}</p>
            <p className='privacy_subContent'><b>{t('privacy_subcontent_7_42_bold')}</b></p>
            <p className='privacy_subContent' dangerouslySetInnerHTML={{ __html: t('privacy_subcontent_7_43') }} />

            <h2 className='privacy_subhead'>{t('privacy_subhead_7_5')}</h2>
            <p className='privacy_subContent'>{t('privacy_subcontent_7_51')}</p>

          </List>
        </div>
      </div>
    </div>
  )
}
