const routesLookup = [
  {
    en: '/',
    de: '/de/',
  },
  {
    en: '/success-stories',
    de: '/de/erfolgsgeschichten',
  },
  {
    en: '/success-stories/trio-mannhiem',
    de: '/de/erfolgsgeschichten/trio-mannhiem',
  },
  {
    en: '/success-stories/gruene-aue',
    de: '/de/erfolgsgeschichten/gruene-aue',
  },
  {
    en: '/success-stories/wago-headquarters',
    de: '/de/erfolgsgeschichten/wago-headquarters',
  },
  {
    en: '/success-stories/phoenix-contact',
    de: '/de/erfolgsgeschichten/phoenix-contact',
  },
  {
    en: '/legal-information',
    de: '/de/impressum',
  },
  {
    en: '/privacypolicy',
    de: '/de/datenschutzerklärung',
  },
  {
    en: '/about-us',
    de: '/de/ueber-uns',
  },
  {
    en: '/careers',
    de: '/de/karriere',
  },
  {
    en: '/careers/head-of-sales',
    de: '/de/karriere/head-of-sales',
  },
  {
    en: '/careers/technical-sales-executive',
    de: '/de/karriere/technical-sales-executive',
  },
  {
    en: '/services/building-analytics',
    de: '/de/leistungen/gebaeudeanalysen',
  },
  {
    en: '/services/smart-building-control',
    de: '/de/leistungen/intelligente-gebaeudesteuerung',
  },
  {
    en: '/services/3d-digital-models-of-buildings',
    de: '/de/leistungen/gebaeudedigitalisierung',
  },
  {
    en: '/contact',
    de: '/de/kontakt',
  }
]

export default routesLookup
