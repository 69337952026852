import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import LegalInformation from '../legalInformation/LegalInformation'
import { Helmet } from 'react-helmet-async'

export default function index() {
  return (
    <>
      <Helmet>
        <link rel='alternate' href='https://leaftech.eu/legal-information' hreflang='en' />
        <link rel='alternate' href='https://leaftech.eu/de/impressum' hreflang='de' />
      </Helmet>
      
      <div className='App'>
        <div className=''>
          <Header />
          <LegalInformation />
          <Footer />
        </div>
      </div>
    </>
  )
}
