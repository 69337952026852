import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FlatButton from '../View/FlatButton/FlatButton'
import { Backdrop, ButtonsRow, Card, Text, Title, Wrapper } from './cookieCard_styles'

const CookieCard = () => {
  // Hooks
  const { t: text } = useTranslation('translation', {
    keyPrefix: 'cookieCard',
  })
  const [userCookieChoice, setuserCookieChoice] = useState({ reacted: false, accepted: false })

  useEffect(() => {
    const localUserCookieChoice = JSON.parse(localStorage.getItem('userCookieChoice'))
    if (localUserCookieChoice) setuserCookieChoice(localUserCookieChoice)
  }, [])

  if (userCookieChoice.reacted) return <></>

  // Render
  return (
    <>
      <Backdrop />

      <Card>
        <Title>{text('title')}</Title>
        <Text dangerouslySetInnerHTML={{ __html: text('text') }} />
        <ButtonsRow>
          <FlatButton
            variant='outlined'
            color='#43444A'
            onClick={() => {
              setuserCookieChoice({ reacted: true, accepted: false })
              localStorage.setItem('userCookieChoice', JSON.stringify({ reacted: true, accepted: false }))
            }}
            label={text('reject')}
          />
          <FlatButton
            variant='contained'
            color='#0087FF'
            onClick={() => {
              setuserCookieChoice({ reacted: true, accepted: true })
              localStorage.setItem('userCookieChoice', JSON.stringify({ reacted: true, accepted: true }))
            }}
            label={text('accept')}
          />
        </ButtonsRow>
      </Card>
    </>
  )
}

export default CookieCard
