import React from "react";
import "./index.css";
import Slider from "../../../../assest/Icons2/control.svg";
import ChartPie from "../../../../assest/Icons2/chart.svg";
import BatteryCharging from "../../../../assest/Icons2/battery.svg";
import Tree from "../../../../assest/Icons2/tree.svg";
import GlobeHemisphereEast from "../../../../assest/Icons2/globe.svg";
import Mac from "../../../../assest/Images/services/Analytics/analytics_laptop.gif"
import { useTranslation } from 'react-i18next';//translator
import { Link } from "react-router-dom";


export default function index() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t, i18n } = useTranslation();//translator

  return (
    <div className="container">
    <div className="row service_benefits_section">
    <div className="text-gray-600 body-font">
      <div className="service_benfits_container">
        <div className="service_benfits_content">
            <h2 style={{fontFamily:"Exo2-Bold", maxWidth:'940px'}} className="service_3d_head">
            {t('analytics_service_results_benefits_head')}
            </h2>
            <ul className="space-y-5" style={{marginTop:'40px', marginBottom: '64px'}}>
              <li className="flex space-x-5">
                <img className="h-fit project_icons" alt="service" src={Slider} />
                <p className="project_icon_text" style={{fontFamily:"NunitoSans-Regular"}}>
                {t('analytics_service_icon_text1')}
                </p>
              </li>
              <li className="flex space-x-5">
                <img className="h-fit project_icons" alt="service" src={ChartPie} />
                <p className="project_icon_text" style={{fontFamily:"NunitoSans-Regular"}}>
                {t('analytics_service_icon_text2')}
                </p>
              </li>

              {t('model_service_contact') === "Contact us" ?
              <li className="flex space-x-5">
                <img className="h-fit project_icons" alt="service" src={BatteryCharging} />
                <p className="project_icon_text" style={{fontFamily:"NunitoSans-Regular"}}>
                {t('analytics_service_icon_text3')}
                </p>
              </li>
              :
              <li className="flex space-x-5" style={{marginTop:'20px'}}>
              <img className="h-fit project_icons" alt="service" src={BatteryCharging} />
              <p className="project_icon_text" style={{fontFamily:"NunitoSans-Regular"}}>
              {t('analytics_service_icon_text3')}
              </p>
            </li>
             }

              {t('model_service_contact') === "Contact us" ?
                <li className="flex space-x-5">
                  <img className="h-fit project_icons" alt="service" src={Tree} />
                  <p className="project_icon_text" style={{fontFamily:"NunitoSans-Regular"}}>
                  {t('analytics_service_icon_text4')}
                  </p>
                </li>
              :
                <li className="flex space-x-5" style={{marginTop:'20px'}}>
                  <img className="h-fit project_icons" alt="service" src={Tree} />
                  <p className="project_icon_text" style={{fontFamily:"NunitoSans-Regular"}}>
                  {t('analytics_service_icon_text4')}
                  </p>
                </li>
              }
              <li className="flex space-x-5">
                <img className="h-fit project_icons" alt="service" src={GlobeHemisphereEast} />
                <p className="project_icon_text" style={{fontFamily:"NunitoSans-Regular"}}>
                {t('analytics_service_icon_text5')}
                </p>
              </li>
            </ul>
            {t('model_service_contact') === "Contact us" ?
            <div className="flex w-full  justify-start items-end">
              <Link style={{ all: "unset" }} to="/contact">
                <button style={{fontFamily: "NunitoSans-Regular"}} className="contact_us2 service_bottom_contacthide">
                {t('model_service_contact')}
                </button>
                </Link>

                <Link style={{ all: "unset" }} to="/contact">
                <button style={{fontFamily: "NunitoSans-Regular"}} className="service_bottom_contact_us">
                {t('model_service_contact')}
                </button>
                </Link>
            </div>
            :
            <div className="flex w-full  justify-start items-end">
              <Link style={{ all: "unset" }} to="/de/kontakt">
                <button style={{fontFamily: "NunitoSans-Regular"}} className="contact_us2 service_bottom_contacthide">
                {t('model_service_contact')}
                </button>
                </Link>

                <Link style={{ all: "unset" }} to="/de/kontakt">
                <button style={{fontFamily: "NunitoSans-Regular"}} className="service_bottom_contact_us">
                {t('model_service_contact')}
                </button>
                </Link>
            </div>
          }
          </div>
          <div>
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={Mac}
            />
          </div>
        </div>
      </div>

      {/* tablet */}
      {/* <div className="service3_t text-gray-600 body-font ">
        <div className="container px-3 py-5 mx-auto items-center bg-gray-100">
          <div className=" items-center text-left">
            <h2 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 capitalize">
              what can you do with this model?
            </h2>
            <p className="mb-8 leading-relaxed">
              Chillwave portland ugh, knausgaard fam polaroid iPhone. Man braid.
            </p>
            <p className="mb-8 leading-relaxed">
              Chillwave portland ugh, knausgaard fam polaroid iPhone. Man braid.
            </p>
            <p className="mb-8 leading-relaxed">
              Chillwave portland ugh, knausgaard fam polaroid iPhone. Man braid.
            </p>
            <p className="mb-8 leading-relaxed">
              Chillwave portland ugh, knausgaard fam polaroid iPhone. Man braid.
            </p>

            <div className="flex w-full  justify-center text-center items-end">
              <div className="relative mr-4 md:w-full lg:w-full xl:w-1/2 w-2/4">
                <button className=" bg-gray-700 rounded border border-slate-900 text-white focus:ring-2 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out capitalize">
                  contact page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
    </div>
  );
}
