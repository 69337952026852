import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import Wago1 from './wago1'
import Wago2 from './wago2'
import Wago3 from './wago3'
import Wago4 from './wago4'
import Wago5 from './../Mannheim/mann5'
import Wago6 from './wago6'
import { Helmet } from 'react-helmet-async'

export default function index() {
  return (
    <>
      <Helmet>
        <link rel='alternate' href='https://leaftech.eu/success-stories/wago-headquarters' hreflang='en' />
        <link rel='alternate' href='https://leaftech.eu/de/erfolgsgeschichten/wago-headquarters' hreflang='de' />
      </Helmet>

      <div className='App'>
        <div className=''>
          <Header />
          <Wago1 />
          <Wago2 />
          <Wago3 />
          <Wago4 />
          <Wago5 />
          <Wago6 />
          <Footer />
        </div>
      </div>
    </>
  )
}
