import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import P1 from './p1'
import P2 from './p2'
import P3 from './p3'
import P4 from './p4'
import P5 from './../Mannheim/mann5'
import P6 from './p6'
import { Helmet } from 'react-helmet-async'
export default function index() {
  return (
    <>
      <Helmet>
        <link rel='alternate' href='https://leaftech.eu/success-stories/phoenix-contact' hreflang='en' />
        <link rel='alternate' href='https://leaftech.eu/de/erfolgsgeschichten/phoenix-contact' hreflang='de' />
      </Helmet>

      <div className='App'>
        <div className=''>
          <Header />
          <P1 />
          <P2 />
          <P3 />
          <P4 />
          <P5 />
          <P6 />
          <Footer />
        </div>
      </div>
    </>
  )
}
