import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Index from './Conindex'
import { Helmet } from 'react-helmet-async'
export default function Contact() {
  return (
    <>
      <Helmet>
        <link rel='alternate' href='https://leaftech.eu/contact' hreflang='en' />
        <link rel='alternate' href='https://leaftech.eu/de/kontakt' hreflang='de' />
      </Helmet>
      
      <div className='App'>
        <div className='contact__leaf'>
          <Header />
          <Index />
          <Footer />
        </div>
      </div>
    </>
  )
}
