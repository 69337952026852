import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Careers from './Careers'
import { Helmet } from 'react-helmet-async'

export default function Index() {
  return (
    <>
      <Helmet>
        <link rel='alternate' href='https://leaftech.eu/careers' hreflang='en' />
        <link rel='alternate' href='https://leaftech.eu/de/karriere' hreflang='de' />
      </Helmet>

      <div className='App'>
        <div className='project__wrapper'>
          <Header />
          <Careers />
          <Footer />
        </div>
      </div>
    </>
  )
}
