import styled from '@emotion/styled'

export const SiteNotice = styled.h2`
  font-family: 'Exo2-Bold';
  font-size: 40px;
  margin-bottom: 1rem;
`

export const LeaftechName = styled.b`
  font-family: 'Nunito Sans';
  font-weight: 800;
  font-size: 21px;
  line-height: 32px;
`
