import styled from '@emotion/styled'

const mobileBrk = '800px'

export const List = styled.ol`
  list-style-type: decimal;

  b {
    font-size: 20px;
    
    @media (width < ${mobileBrk}) {
      font-size: 16px;
    }
  }
  
  a {
    color: #51a0e6;
    font-size: 20px;

    @media (width < ${mobileBrk}) {
      font-size: 12px;
    }
  }
`

export const ListItem = styled.li`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.0025em;
  color: #2f2f2f;
  margin-top: 120px;
  text-align: left;
  padding: 0px;
  font-family: 'Exo2-Regular';
  margin-left: 1em;

  @media (width <= ${mobileBrk}) {
    font-size: 24px;
    margin-top: 60px;
  }
`

export const SubContentList = styled.ul`
  list-style-type: disc;
  padding-left: 2rem !important;

  @media (width <= ${mobileBrk}) {
    padding-left: 1rem !important;
    }

  li {
    font-size: 20px;
    margin: 1rem 0;

    @media (width <= ${mobileBrk}) {
      font-size: 12px;
    }
  }
`

