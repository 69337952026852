import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import ProjH from './Projects/projectHead'
import ProjF from './Projects/projectFoot'
import Project1 from './Projects/project1'
import { useTranslation } from 'react-i18next' //translator
import { Helmet } from 'react-helmet-async'

export default function Project() {
  const { t, i18n } = useTranslation() //translator

  return (
    <>
      <Helmet>
        <link rel='alternate' href='https://leaftech.eu/success-stories' hreflang='en' />
        <link rel='alternate' href='https://leaftech.eu/de/erfolgsgeschichten' hreflang='de' />

        {t('model_service_contact') === 'Contact us' ? (
          <>
            <title>Success Stories | Leaftech</title>
            <meta
              name='description'
              content='We have served more than half a million sqm and are growing fast. Check out our case studies to know how our data services optimize buildings.'
            />
            <link rel='canonical' href='/success-stories' />
          </>
        ) : (
          <>
            <title>Case Studies | Success Stories | Leaftech</title>
            <meta name='description' content='Success Stories' />
            <link rel='canonical' href='/de/erfolgsgeschichten' />
          </>
        )}
      </Helmet>

      <div className='App'>
        <div className='project__wrapper'>
          <Header />
          <ProjH />
          <Project1 />
          <ProjF />
          <Footer />
        </div>
      </div>
    </>
  )
}
