import React from 'react'
import { useTranslation } from 'react-i18next' //translator
import { LeaftechName, SiteNotice } from './legalInformation_styles'

export default function LegalInformation() {
  const { t, i18n } = useTranslation() //translator

  return (
    <div className='row'>
      <div className='row privacy_landing_toprow'>
        <div className='container'>
          <div className='privacy_head'>
            <h1
              style={{ fontFamily: 'Exo2-Bold' }}
              className='single_project_main_head '
            >
              {t('legal_main_head')}
            </h1>
          </div>
        </div>
      </div>

      <div
        className='container'
        style={{ marginTop: '00px', marginBottom: '90px', textAlign: 'left' }}
      >
        <div className='row'>
          <div className='legal_content_container'>
            <SiteNotice>{t('legal_site_notice')}</SiteNotice>

            <h3 className='legal_subhead mt-20'>{t('legal_subhead1')}</h3>
            <LeaftechName>{t('legal_subcontent_leaftechName')}</LeaftechName>
            <p
              className='legal_subcontent mb-2'
              dangerouslySetInnerHTML={{ __html: t('legal_subcontent11') }}
            />
            <p
              className='legal_subcontent mb-2'
              dangerouslySetInnerHTML={{ __html: t('legal_subcontent12') }}
            />
            <p
              className='legal_subcontent mb-2'
              dangerouslySetInnerHTML={{ __html: t('legal_subcontent13') }}
            />

            <h3 className='legal_subhead mt-20'>{t('legal_subhead2')}</h3>
            <p
              className='legal_subcontent mb-2'
              dangerouslySetInnerHTML={{ __html: t('legal_subcontent21') }}
            />

            <h3 className='legal_subhead mt-20'>{t('legal_subhead3')}</h3>
            <p
              className='legal_subcontent mb-2'
              dangerouslySetInnerHTML={{ __html: t('legal_subcontent31') }}
            />

            <h3 className='legal_subhead mt-20'>{t('legal_subhead4')}</h3>
            <p
              className='legal_subcontent mb-2'
              dangerouslySetInnerHTML={{ __html: t('legal_subcontent41') }}
            />

            <h3 className='legal_subhead mt-20'>{t('legal_subhead5')}</h3>
            <p
              className='legal_subcontent mb-2'
              dangerouslySetInnerHTML={{ __html: t('legal_subcontent51') }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
