import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react'
import MenuSharpIcon from '@mui/icons-material/MenuSharp'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import Downarrow from '../../assest/Icons/chevron.png'
import logo from '../../assest/Images/LEAFTECH.svg'
import './Header.css'
import { Outlet, NavLink, Link, useHref, useNavigate } from 'react-router-dom'
import $ from 'jquery'
import { useTranslation } from 'react-i18next' //translator
import i18next from 'i18next'
import { LanguageKey } from './header_styles'
import routesLookup from '../../Routes/routes_lookup'

export default function Header({ history }) {
  const { t, i18n } = useTranslation() //translator
  const [showMenu, setMenu] = useState(false)
  const [semi, setBold] = useState(false)
  const href = useHref()
  const navigate = useNavigate()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (href.includes('/de') || href.includes('.de') || href.includes('/deu')) i18next.changeLanguage('de')
    else i18next.changeLanguage('en')
  }, [href])

  const handleMobile = () => {
    setMenu(!showMenu)
  }

  const handleClick = event => {
    console.log(event.currentTarget, 'hi')
    // event.currentTarget.style.fontFamily = 'NunitoSans-ExtraBold';
  }

  /**************************************
   ******** Find adjacent route in other language
   *************************************/
  const goToAdjacentRoute = () => {
    const currentRoute = routesLookup.find(({ en, de }) => href === en || href === de)

    if (href.includes('/de/')) navigate(currentRoute.en)
    else navigate(currentRoute.de)
  }

  /**************************************
   ******** Render
   *************************************/
  return (
    <div>
      <div className='arun_container'>
        <div className='container container-flex-display header_container'>
          <div className='arun_logo'>
            {/* <h1 style={{ fontFamily: "Exo2-Regular" }} className="uppercase font-bold tracking-wide">
            LeafTech
          </h1> */}
            {t('model_service_contact') === 'Contact us' ? (
              <Link to='/'>
                <img src={logo} alt='logo' className='responsive leaftech_logo desktop_logo' />
              </Link>
            ) : (
              <Link to='/de/'>
                <img src={logo} alt='logo' className='responsive leaftech_logo desktop_logo' />
              </Link>
            )}
          </div>
          {showMenu === false ? (
            <div className='arun_menuList' id='sub-header'>
              <ul className='arunUL' id='arunUL'>
                <li>
                  {t('model_service_contact') === 'Contact us' ? (
                    <NavLink id='dd_link' onClick={handleClick} className='dd_link' to='/'>
                      {/* Home */}
                      {t('Home_text')}
                    </NavLink>
                  ) : (
                    <NavLink id='dd_link' onClick={handleClick} className='dd_link' to='/de/'>
                      {/* Home */}
                      {t('Home_text')}
                    </NavLink>
                  )}
                </li>
                <li>
                  {t('model_service_contact') === 'Contact us' ? (
                    <NavLink id='dd_link' onClick={handleClick} className='dd_link' to='/success-stories'>
                      {/* Project */}
                      {t('Project_text')}
                    </NavLink>
                  ) : (
                    <NavLink id='dd_link' onClick={handleClick} className='dd_link' to='/de/erfolgsgeschichten'>
                      {/* Project */}
                      {t('Project_text')}
                    </NavLink>
                  )}
                </li>
                {/* <NavLink className="navFont" to="/service">Services</NavLink> */}
                <li>
                  <div className='dropdown'>
                    <button className='dropbtn '>
                      {' '}
                      <p className='' style={{ fontWeight: '500' }}>
                        {t('Services_text')}
                      </p>
                      <img
                        alt='Downarrow'
                        id='dropbtnimg'
                        className='mt-1.5 ml-2'
                        src={Downarrow}
                        width='15'
                        height='30'
                      />
                    </button>
                    <div className='dropdown-content'>
                      {t('model_service_contact') === 'Contact us' ? (
                        <NavLink
                          id=''
                          onClick={handleClick}
                          className=''
                          to='/services/3d-digital-models-of-buildings'
                          activeClassName='active-link'
                        >
                          {t('Home_section3_service1')}
                        </NavLink>
                      ) : (
                        <NavLink
                          id=''
                          onClick={handleClick}
                          className=''
                          to='/de/leistungen/gebaeudedigitalisierung'
                          activeClassName='active-link'
                        >
                          {t('Home_section3_service1')}
                        </NavLink>
                      )}

                      {t('model_service_contact') === 'Contact us' ? (
                        <NavLink
                          id=''
                          onClick={handleClick}
                          className=''
                          to='/services/building-analytics'
                          activeClassName='active-link'
                        >
                          {t('Home_section3_service2')}
                        </NavLink>
                      ) : (
                        <NavLink
                          id=''
                          onClick={handleClick}
                          className=''
                          to='/de/leistungen/gebaeudeanalysen'
                          activeClassName='active-link'
                        >
                          {t('Home_section3_service2')}
                        </NavLink>
                      )}

                      {t('model_service_contact') === 'Contact us' ? (
                        <NavLink
                          id=''
                          onClick={handleClick}
                          className=''
                          to='/services/smart-building-control'
                          activeClassName='active-link'
                        >
                          {t('Home_section3_service3')}
                        </NavLink>
                      ) : (
                        <NavLink
                          id=''
                          onClick={handleClick}
                          className=''
                          to='/de/leistungen/intelligente-gebaeudesteuerung'
                          activeClassName='active-link'
                        >
                          {t('Home_section3_service3')}
                        </NavLink>
                      )}
                    </div>
                  </div>
                </li>

                <li>
                  <div className='dropdown'>
                    <button className='dropbtn '>
                      {' '}
                      <p className='' style={{ fontWeight: '500' }}>
                        {t('Company_text')}
                      </p>
                      <img
                        alt='Downarrow'
                        id='dropbtnimg'
                        className='mt-1.5 ml-2'
                        src={Downarrow}
                        width='15'
                        height='30'
                      />
                    </button>
                    {t('model_service_contact') === 'Contact us' ? (
                      <div className='dropdown-content'>
                        <NavLink id='' onClick={handleClick} className='' to='/about-us' activeClassName='active-link'>
                          {t('Company_option1')}
                        </NavLink>
                        <NavLink id='' onClick={handleClick} className='' to='/careers' activeClassName='active-link'>
                          {t('Company_option2')}
                        </NavLink>
                      </div>
                    ) : (
                      <div className='dropdown-content'>
                        <NavLink
                          id=''
                          onClick={handleClick}
                          className=''
                          to='/de/ueber-uns'
                          activeClassName='active-link'
                        >
                          {t('Company_option1')}
                        </NavLink>
                        <NavLink
                          id=''
                          onClick={handleClick}
                          className=''
                          to='/de/karriere'
                          activeClassName='active-link'
                        >
                          {t('Company_option2')}
                        </NavLink>
                      </div>
                    )}
                  </div>
                </li>

                <div className='contactandLanguage'>
                  <ul className='arunUL' style={{ gap: '0px' }}>
                    <li className=''>
                      {t('model_service_contact') === 'Contact us' ? (
                        <Link to='/contact'>
                          <button
                            className='Contact_btn'
                            type='button'
                            style={{ fontFamily: 'NunitoSans-SemiBold', marginRight: '64px' }}
                          >
                            {t('Contact_text')}
                          </button>
                        </Link>
                      ) : (
                        <Link to='/de/kontakt'>
                          <button
                            className='Contact_btn'
                            type='button'
                            style={{ fontFamily: 'NunitoSans-SemiBold', marginRight: '64px' }}
                          >
                            {t('Contact_text')}
                          </button>
                        </Link>
                      )}
                    </li>

                    <li className='lang_options'>
                      <LanguageKey
                        id='langContent'
                        onClick={goToAdjacentRoute}
                        style={{ marginRight: '24px' }}
                        isActive={t('model_service_contact') === 'Contact us'}
                        // onClick={() => i18next.changeLanguage('en')}
                      >
                        EN
                      </LanguageKey>
                    </li>
                    <li>
                      <LanguageKey
                        id='langContent'
                        onClick={goToAdjacentRoute}
                        style={{ marginRight: '20px', marginTop: '0px' }}
                        isActive={t('model_service_contact') === 'Kontaktieren Sie uns'}
                        // onClick={() => i18next.changeLanguage('de')}
                      >
                        DE
                      </LanguageKey>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
          ) : (
            // mobile view header

            <div className='arun_mobilemenuList'>
              <ul className='arunUL' id='arunUL'>
                <li>
                  <NavLink
                    id='dd_link'
                    onClick={handleClick}
                    style={{ fontFamily: 'NunitoSans-Regular' }}
                    className='dd_link'
                    to='/'
                  >
                    {/* Home */}
                    {t('Home_text')}
                  </NavLink>
                </li>
                <li>
                  {t('model_service_contact') === 'Contact us' ? (
                    <NavLink
                      id='dd_link'
                      onClick={handleClick}
                      style={{ fontFamily: 'NunitoSans-Regular' }}
                      className='dd_link'
                      to='/success-stories'
                    >
                      {/* Project */}
                      {t('Project_text')}
                    </NavLink>
                  ) : (
                    <NavLink
                      id='dd_link'
                      onClick={handleClick}
                      style={{ fontFamily: 'NunitoSans-Regular' }}
                      className='dd_link'
                      to='/de/erfolgsgeschichten'
                    >
                      {/* Project */}
                      {t('Project_text')}
                    </NavLink>
                  )}
                </li>
                {/* <NavLink className="navFont" to="/service">Services</NavLink> */}
                <li>
                  <div className='accordian_container'>
                    <div
                      className='accordion'
                      id='myAccordion'
                      style={{ background: 'rgba(255, 255, 255, 0.5) !important;' }}
                    >
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingOne'>
                          <button
                            type='button'
                            style={{ fontFamily: 'NunitoSans-Regular' }}
                            className='accordion-button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseOne'
                          >
                            <p className='' style={{ fontWeight: '400' }}>
                              {t('Services_text')}
                            </p>
                          </button>
                        </h2>
                        <div id='collapseOne' className='accordion-collapse collapse ' data-bs-parent='#myAccordion'>
                          <div className='card-body'>
                            {t('model_service_contact') === 'Contact us' ? (
                              <NavLink
                                id=''
                                onClick={handleClick}
                                className=''
                                style={{ fontFamily: 'NunitoSans-Regular' }}
                                to='/services/smart-building-control'
                                activeClassName='active-link'
                              >
                                {t('Home_section3_service3')}
                              </NavLink>
                            ) : (
                              <NavLink
                                id=''
                                onClick={handleClick}
                                className=''
                                style={{ fontFamily: 'NunitoSans-Regular' }}
                                to='/de/leistungen/intelligente-gebaeudesteuerung'
                                activeClassName='active-link'
                              >
                                {t('Home_section3_service3')}
                              </NavLink>
                            )}

                            {t('model_service_contact') === 'Contact us' ? (
                              <NavLink
                                id=''
                                onClick={handleClick}
                                className=''
                                style={{ fontFamily: 'NunitoSans-Regular' }}
                                to='/services/building-analytics'
                                activeClassName='active-link'
                              >
                                {t('Home_section3_service2')}
                              </NavLink>
                            ) : (
                              <NavLink
                                id=''
                                onClick={handleClick}
                                className=''
                                style={{ fontFamily: 'NunitoSans-Regular' }}
                                to='/de/leistungen/gebaeudeanalysen'
                                activeClassName='active-link'
                              >
                                {t('Home_section3_service2')}
                              </NavLink>
                            )}

                            {t('model_service_contact') === 'Contact us' ? (
                              <NavLink
                                id=''
                                onClick={handleClick}
                                className=''
                                style={{ fontFamily: 'NunitoSans-Regular' }}
                                to='/services/3d-digital-models-of-buildings'
                                activeClassName='active-link'
                              >
                                {t('Home_section3_service1')}
                              </NavLink>
                            ) : (
                              <NavLink
                                id=''
                                onClick={handleClick}
                                className=''
                                style={{ fontFamily: 'NunitoSans-Regular' }}
                                to='/de/leistungen/gebaeudedigitalisierung'
                                activeClassName='active-link'
                              >
                                {t('Home_section3_service1')}
                              </NavLink>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingOne'>
                          <button
                            type='button'
                            style={{ fontFamily: 'NunitoSans-Regular' }}
                            className='accordion-button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseTwo'
                          >
                            <p className='' style={{ fontWeight: '400' }}>
                              {t('Company_text')}
                            </p>
                          </button>
                        </h2>
                        <div id='collapseTwo' className='accordion-collapse collapse ' data-bs-parent='#myAccordion'>
                          {t('model_service_contact') === 'Contact us' ? (
                            <div className='card-body'>
                              <NavLink
                                id=''
                                onClick={handleClick}
                                className=''
                                to='/about-us'
                                activeClassName='active-link'
                              >
                                {t('Company_option1')}
                              </NavLink>
                              <NavLink
                                id=''
                                onClick={handleClick}
                                className=''
                                to='/careers'
                                activeClassName='active-link'
                              >
                                {t('Company_option2')}
                              </NavLink>
                            </div>
                          ) : (
                            <div className='card-body'>
                              <NavLink
                                id=''
                                onClick={handleClick}
                                className=''
                                to='/de/ueber-uns'
                                activeClassName='active-link'
                              >
                                {t('Company_option1')}
                              </NavLink>
                              <NavLink
                                id=''
                                onClick={handleClick}
                                className=''
                                to='/de/karriere'
                                activeClassName='active-link'
                              >
                                {t('Company_option2')}
                              </NavLink>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="dropdown">
                  <button className="dropbtn "> <p className="" style={{fontWeight:'500'}}>{t('Services_text')}</p><img alt="Downarrow" id="dropbtnimg" className="mt-1.5 ml-2" src={Downarrow} width="15" height="30" /></button>
                  <div className="dropdown-content">
                    <NavLink id="" onClick={handleClick} className=""  to="/services/3d-digital-models-of-buildings" activeClassName="active-link">
                    {t('Home_section3_service1')}
                    </NavLink>
                    <NavLink id="" onClick={handleClick} className=""  to="/building-analytics" activeClassName="active-link">
                    {t('Home_section3_service2')}
                    </NavLink>
                    <NavLink id="" onClick={handleClick} className=""  to="/services/smart-building-control" activeClassName="active-link">
                    {t('Home_section3_service3')}
                    </NavLink>
                  </div>
                </div> */}
                </li>

                <div className='contactandLanguage'>
                  <ul className='arunUL' style={{ gap: '0px' }}>
                    <li>
                      <label className='lang_label' style={{ fontFamily: 'NunitoSans-Regular' }}>
                        Language settings
                      </label>
                      <div style={{ display: 'flex' }}>
                        <li className='lang_options'>
                          {t('model_service_contact') === 'Contact us' ? (
                            <button
                              id='langContent'
                              onClick={() => i18next.changeLanguage('en')}
                              style={{ fontFamily: 'NunitoSans-ExtraBold', marginRight: '24px' }}
                            >
                              English
                            </button>
                          ) : (
                            <button
                              id='langContent'
                              onClick={() => i18next.changeLanguage('en')}
                              style={{ fontFamily: 'NunitoSans-SemiBold', marginRight: '24px' }}
                            >
                              English
                            </button>
                          )}
                        </li>
                        <li>
                          {t('model_service_contact') === 'Contact us' ? (
                            <button
                              id='langContent'
                              onClick={() => i18next.changeLanguage('de')}
                              style={{ fontFamily: 'NunitoSans-SemiBold', marginRight: '20px', marginTop: '0px' }}
                            >
                              German
                            </button>
                          ) : (
                            <button
                              id='langContent'
                              onClick={() => i18next.changeLanguage('de')}
                              style={{ fontFamily: 'NunitoSans-ExtraBold', marginRight: '20px', marginTop: '0px' }}
                            >
                              German
                            </button>
                          )}
                        </li>
                      </div>
                    </li>

                    <li className=''>
                      {t('model_service_contact') === 'Contact us' ? (
                        <Link to='/contact'>
                          <button
                            className='Contact_btn nav_mobile_contact'
                            type='button'
                            style={{ fontFamily: 'NunitoSans-SemiBold', marginRight: '64px' }}
                          >
                            {t('Contact_text')}
                          </button>
                        </Link>
                      ) : (
                        <Link to='/de/kontakt'>
                          <button
                            className='Contact_btn nav_mobile_contact'
                            type='button'
                            style={{ fontFamily: 'NunitoSans-SemiBold', marginRight: '64px' }}
                          >
                            {t('Contact_text')}
                          </button>
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
          )}
        </div>
        <div className='mobile_menubutton'>
          {showMenu === false ? <MenuSharpIcon onClick={handleMobile} /> : <CloseSharpIcon onClick={handleMobile} />}
        </div>
      </div>

      <Outlet />
    </div>
  )
}
